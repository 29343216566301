// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import UserLibraryController from "../../catalogue/src/UserLibraryController.web";
import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal, Spin, Button } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import _ from "lodash";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import './UserLibrary.css';
import { noData, download, deleteFile, more } from "./assets";
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
class Physiotherapy extends UserLibraryController {
	constructor(props: any) {
		super(props);
	}

    async componentDidMount(){
        this.getFiles()
      }


	getBase64 = (img: RcFile, callback: (url: string) => void) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result as string));
		reader.readAsDataURL(img);
	  };
	
    uploadFile = (event: any) => {
        if (event?.target.files?.length) {
            const file = event?.target.files[0];
            this.createFile(this.getUrlParameter("folderType"), this.getUrlParameter("id"), file);
            this.setState({document: file as File})
            this.getBase64(file as File, url => {
                this.setState({documentUrl: url});
            });
    }
    }

    confirmUploadFile = (files: any) => {
        if (files?.length) {
            const file = files[0];
            this.replaceFile(file, this.getUrlParameter("id"));
            this.setState({document: file as File})
            this.getBase64(file as File, url => {
                this.setState({documentUrl: url});
            });
    }
    }

    renderModalsDelete() {
		return (
			<Modal
			  className="delete-modal"
			  id="delete-modal"
			  open={this.state.showDeleteModel}
			  onCancel={this.hideDeleteModel}
			  footer={null}
			>
			<>
			  <div style={{
				padding: '50px 25px 20px 25px', fontSize: '16px',
				marginTop: '20px',fontWeight: 'bold'
			  }}>
				Are you sure you want to delete "{this.state.fileName}" from documents?
			  </div>
			  <div style={{ display: "flex",
			   justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{
					  height: "56px",width: "120px",
					  background: "#ffff",
					  borderColor: "#ffff",
					  borderRadius: "8px",color: "#830065",
					  fontSize: '16px',
					  fontWeight: '700'
					}}
					onClick={()=>this.hideDeleteModel()} >Cancel</Button>
				<Button
                    loading={this.state.deleteFileLoader}
					data-testid="delete-app"
					style={{
					  height: "56px",
					  width: "120px",background: "#830065",
					  borderColor: "#830065",
					  borderRadius: "8px",color: "white",
					  fontSize: '16px',
					  fontWeight: '700'}}
					onClick={() => this.deleteFile(this.state.fileID)} >Okay</Button>
				</div>
			</>
			</Modal>
		);
	}

    renderFileContent() {
        const { documents } = this.state.singleFileData || {};
        if (documents) {
            if (documents.includes('.mp4')) {
                return (
                    <video id='videoElement' style={{width: '100%', borderRadius: '8px 8px 28px 8px'}} controls>
                        <source src={documents} type="video/mp4" />
                    </video>
                );
            } else if (documents.match(/\.(png|jpe?g)$/)) {
                return (
                    <div style={{ padding: '40px' }}>
                        <img src={documents} className='file-image' />
                    </div>
                );
            }
        }
    
        return null;
    }

    renderReplaceModal() {
		return (
			<Modal
			  className="delete-modal"
			  id="replace-modal"
			  open={this.state.showReplaceModal}
			  onCancel={this.hideReplaceModel}
			  footer={null}
			>
			<>
			  <div style={{
				padding: '50px 25px 20px 25px', fontSize: '16px',
				marginTop: '20px',fontWeight: 'bold'
			  }}>
				The file already exists. Do you want to replace the existing file?
			  </div>
              <div style={{ 
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: '8px', // Adjust this to increase/decrease space
    padding: '0 22px 22px 22px' 
}}>
    <Button
        data-testid="cancel-replace"
        style={{
            height: "56px", 
            minWidth: "100px", 
            background: "#fff",
            borderColor: 'white',
            color: "#830065",
            fontSize: '16px',
            fontWeight: '700',
            borderRadius: "8px"
        }}
        onClick={() => this.hideReplaceModel()}
    >
        Cancel
    </Button>

    <Button
        data-test-id="replace"
        style={{
            height: "56px", 
            minWidth: "120px", 
            background: "#830065", 
            borderColor: "#830065",
            color: "#fff",
            fontSize: '16px',
            fontWeight: '700',
            borderRadius: "8px"
        }}
        onClick={() => this.confirmUploadFile([this.state.document])}
    >
        Yes, Replace
    </Button>
</div>
			</>
			</Modal>
		);
	}
    
	render(): React.ReactNode {
		return (
			<PageWrapper title={_.capitalize(this.getUrlParameter("folderType")).replaceAll("_", " ")} hasBack>
            <div>
            {this.getUrlParameter("folderType") !== "case_management" && (
            <div className='create-file-div'>
                <label htmlFor="actual-btn">
                <PlusCircleOutlined className='add-icon3'/>
                </label>
                <input id="actual-btn" data-test-id="upload" type="file" onChange={this.uploadFile} hidden />
                <label className='add-file' id="create" htmlFor="actual-btn">Add File</label>
            </div>
            )}

            <div id='physiotherapy-id' className='case-management-content'>
            <div className='file-search-div'>
                <p data-test-id="title" className='case-title'>
                    {this.getUrlParameter("folderType") === "case_management" ? "Documents" : "Files"}
                </p>
                {this.getUrlParameter("folderType") === "case_management" && (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <label className='add-file' id="create" htmlFor="actual-btn">
                    <PlusCircleOutlined className='add-icon3'/>
                    </label>
                    <input id="actual-btn" data-test-id="upload" type="file" onChange={this.uploadFile} hidden />
                    <label className='add-file' id="create" htmlFor="actual-btn">Add File</label>
                </div>
                )}
            </div>
            
            <TableContainer component={Paper} style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Table aria-label="simple table" style={{ minWidth: '800px' }}>
                    <TableHead style={{ backgroundColor: '#F1F5F9' }}>
                    <TableRow>
                        <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '35%', textAlign: 'left' }}>Name</TableCell>
                        <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '25%', textAlign: 'left' }}>Uploaded by</TableCell>
                        <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%', textAlign: 'left' }}>Added on</TableCell>
                        <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '25%', textAlign: 'left' }}>Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.fileData && this.state.fileData.length > 0 ? (
                        this.state.fileData.filter( (e: any) => e.attributes.documents !== null).map((row: any, i: any, filteredData: any) => (
                        <TableRow key={i} data-test-id={`tableRow${i}`} className={i === filteredData.length - 1 ? 'last-row' : ''}>
                            <TableCell component="th" scope="row" style={{width: '35%', textAlign: 'left' }}>
                            {row.attributes.document_name}
                            </TableCell>
                            <TableCell style={{width: '25%', textAlign: 'left' }}>{row.attributes.uploaded_by}</TableCell>
                            <TableCell style={{width: '15%', textAlign: 'left' }}>{moment(row.attributes.created_at).format('D MMM YYYY')}</TableCell>
                            <TableCell style={{width: '25%',textAlign: 'left' }}>
                            <div style={{display: 'flex', justifyItems: 'center', justifyContent: 'flex-start' }}>
                            <p
                                className="view"
                                data-test-id={`view${i}`}
                                onClick={() => this.handleDocumentAction(
                                    row.attributes.document_name,
                                    row.attributes.documents,
                                    () => this.handleShowDocument(row.attributes),
                                    this.handleHideDocument
                                )}
                            >
                                View
                            </p>
                                <img data-test-id={`download${i}`} src={download} onClick={() => this.handleDownload(row.attributes.documents, row.attributes.document_name)} style={{height: '24px', cursor: 'pointer', marginLeft: "20px", marginRight: "5px"}}/>
                                <img data-testid="delete-data" src={deleteFile} onClick={() => this.showDeleteModel(row.attributes.id, row.attributes.document_name)} style={{height: '24px', cursor: 'pointer', marginLeft: "5px"}}/>
                            </div>
                            </TableCell> 
                        </TableRow>
                        ))
                    ) : (
                        <TableRow>
                        <TableCell colSpan={4} style={{ borderBottom: 'none', padding: 0 }}>
                            <div className='search-content' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  }}>
                            <img src={noData} alt="No Data" style={{ width: '100px', height: '100px', marginBottom: '10px' }} />
                            <p className='no-data-font'>No data available</p>
                            </div>
                        </TableCell>
                    </TableRow>
                    )}

                        {this.state.loading && (
                        <div className="loader-container">
                            <Spin size="large" />
                        </div>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            </div>
            {this.getUrlParameter("folderType") === "case_management" && (
            <div className='search-content'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className='case-title'>Notes</p>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Link to={`/create-case-management?id=${this.getUrlParameter('id')}&folderType=${this.getUrlParameter('folderType')}`}><PlusCircleOutlined className='add-icon3' /></Link>
                    <p className='add-file' id='add-note' style={{ margin: '0 auto' }}><Link to={`/create-case-management?id=${this.getUrlParameter('id')}&folderType=${this.getUrlParameter('folderType')}`}>Add Note</Link></p>
                </div>
                </div>
                {this.state.fileData && this.state.fileData.length > 0 ? (
                <div onClick={() => { this.setState({ popupVisible: '' }) }} style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '20px' }}>
                    {this.state.fileData.filter((e: any) => e.attributes.title !== null).map((row: any, i: any) => (
                    <div key={i} className='case-management-card' data-test-id={`note${i}`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <p className='case-management-title'>{row.attributes.title}</p>
                        </div>
                        <div style={{ marginLeft: 'auto', position: "relative" }}>
                            <img
                            data-test-id={`more${i}`}
                            src={more}
                            style={{ height: '24px', cursor: "pointer" }}
                            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                this.setState({ popupVisible: row.attributes.id })
                                e?.stopPropagation();
                            }}
                            />
                            {this.state.popupVisible == row.attributes.id && <div className='more-div'>
                            <p data-test-id={`view-note${i}`} id='view'><Link to={`/case-management-details?id=${row.id}`}>View</Link></p>
                            <p id='view' data-test-id="edit-note" className='pop-text'><Link to={`/update-case-management?id=${row.id}`}>Edit</Link></p>
                            <p style={{cursor: 'pointer'}} data-test-id={`delete-note${i}`} onClick={() => this.showDeleteModel(row.attributes.id, row.attributes.title)}>Delete</p>
                            </div>
                            }
                        </div>
                        </div>
                        <div style={{ gridColumn: '1 / 2' }}>
                        <p className='case-management-note'>{row.attributes.entry}</p>
                        </div>
                        <hr style={{ border: 'none', borderBottom: '1px solid #DDDDDD66', width: '100%' }} />
                        <div style={{ gridColumn: '1 / 2' }}>
                        <p className='case-management-date'>Created on <span style={{ fontSize: '12px', fontWeight: '600' }}>{moment(row.attributes.created_at).format('D MMM YYYY | h:mm A')}</span></p>
                        </div>
                    </div>
                    ))}
                </div>
                ) : (
                <div className='search-content'>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '40px' }}>
                    <img src={noData} alt="No Data" style={{ width: '100px', height: '100px', marginBottom: '10px' }} />
                    <h2>No data available</h2>
                    </div>
                </div>
                )}
            </div>
            )}
            {this.state.showDocument && <p data-test-id="singleFileData" onClick={this.handleHideDocument}></p>}
            <Modal
            className='folder-view-modal'
            id="tncModal"
            title={<p className='file-title'>{this.state.singleFileData?.document_name}</p>}
            open={this.state.showDocument}
            onCancel={this.handleHideDocument}
            footer={null}
            width={this.state.singleFileData?.document_name.includes('.pdf') ? { width: `calc(100vw - 100px)` } : 600}
            >
            {this.renderFileContent()}
            </Modal>
            {this.renderModalsDelete()}
            {this.renderReplaceModal()}
            </div>
            </PageWrapper>
		);
	}
}

export default Physiotherapy;

// Customizable Area End