// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FailedToastCenter, SuccessMessage } from "../../../components/src/Toast";
import moment from 'moment';
export const configJSON = require("./config");
import React, {RefObject } from 'react';
export interface Props {
	navigation: any;
	id: string;
	calenderDate: string;
	events: any;
	filterType: string;
	setCalendarDate:(date:string)=>void;
	ChangeBackwardDate:(date:string)=>void;
	showDate:string;
	prevDate:Date
	selectedDayEvents:any
}
interface CaseData {
	attributes: {
		full_name: string;
	};
}
interface AddAppointmentValues {
	name: string;
	title: string;
	location: string;
	purpose: string;
	information: string;
	date:any;
	starttime:any;
	endtime:any;
	type:string
}



interface S {
	appointmentData: any,
	loadingData: boolean,
	filteredAppointmentData: CaseData[],
	showAddModel: boolean,
	showUpdateModel: boolean,

	appointmentType: string,
	startTime: any,
	endTime: any,
	date: any,
	name: string;
	title: string;
	location: string;
	purpose: string;
	information: string;
	titleError:boolean;
	endDateTime:boolean;
	upcomingAppointments: any,
	upcomingAppointmentsCount: number,
	upcomingDateList: any,
	filterStatus: boolean;

	deleteLoad: boolean,
	appointmentID: number,
	showCalendar: boolean,
	status: any,
	calenderDate: any,
	currentdate: any,
	showDetailsIndex:string,
	activeAptRowIndex:string,
	errorMsg:string,
	startErrorMsg:string | null
	filterType: string,
	showDeleteModel:boolean,
	filterOption:any,
	hasBack:boolean,
	pageTitle:string,
	userType:string,
	pageLoad:boolean
	showDate:any,
	prevDate: Date,
    newDate : Date,
	dateValue:Date | null,
	dateEditValue:Date | null,
	dateError:string | null,
	loadPage:boolean
}

interface SS {
	id: any;
}



export default class AppointmentDashboardController extends BlockComponent<Props, S, SS> {
	//cases
	apiGetAppointmentsCallId: string = "";
	apiGetSolicitorAppointmentsFormCallId: string = "";

	apiAddAppontPostCallId: string = "";
	apiGetUpcomingAppointmentsFormCallId: string = "";
	apiDeleteAppointmentsFormCallId: string = "";
	apiUpdateAppointmentsFormCallId: string = "";
	apiGetAppointmentDetailsFormCallId: string = "";
	apiGetClientProfileFormCallId:string="";

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
		];
		this.state = {
			appointmentData: [],
			loadingData: false,
			filteredAppointmentData: [],
			showAddModel: false,
			showUpdateModel: false,
			titleError:false,
			endDateTime:true,
			appointmentType: "",
			startTime: 0,
			endTime: 0,
			date: 0,
			name: "",
			title: "",
			location: "",
			purpose: "",
			information: "",
			upcomingAppointments: [],
			upcomingAppointmentsCount: 0,
			upcomingDateList: [],
			filterStatus: false,
			deleteLoad: false,
			appointmentID: 0,
			showCalendar: false,
			status: "",
			calenderDate: "",
			currentdate: "",
			showDetailsIndex:"",
			activeAptRowIndex:"",
			errorMsg:"",
			startErrorMsg:null,
			filterType: "",
			showDeleteModel:false,
			filterOption:[
				{ label: "In-Person", value: "In_Person" },
				{ label: "Online", value: "Online" },
			],
			hasBack:false,
			pageTitle:"",
			showDate:"",
			dateValue:null,
			dateEditValue:null,
			userType: JSON.stringify(localStorage.getItem("userType")),
			pageLoad:false,
			prevDate: new Date(),
            newDate : new Date(),
			dateError:null,
			loadPage:true
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}
	async receive(from: string, message: Message) {
		if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
			const data = message.getData(
				"rowData"
			);
			localStorage.setItem("clientName", data.attributes.full_name);
			localStorage.setItem("caseID", data.id);
			this.getUpcomingAppointments();

		}
		this.receiveAppointments(message);
		this.receiveAddAppointment(message);
		this.receiveUpcomingAppointment(message);
		this.receiveDeleteAppointment(message);
		this.receiveUpdateAppointment(message);
		this.receiveAppointmentDetails(message);
		this.receiveGetClientProfileDetails(message)
		this.receiveSolicitorAppointments(message)

	}
	async receiveAppointments(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetAppointmentsCallId &&
			this.apiGetAppointmentsCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				this.setState({ pageLoad:false });
				this.setState({ appointmentData: responseJson?.data, loadingData: false, pageLoad:false });
		}
	}
	async receiveSolicitorAppointments(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetSolicitorAppointmentsFormCallId &&
			this.apiGetSolicitorAppointmentsFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				this.setState({ pageLoad:false });
				this.setState({ appointmentData: responseJson?.clients, loadingData: false });
		}
	}
	async receiveAddAppointment(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiAddAppontPostCallId &&
			this.apiAddAppontPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.data){
				this.setState({ loadingData: false, showAddModel: false,errorMsg:"",dateValue:null});
				SuccessMessage("Appointment Added successfully");
				this.setState({ loadingData: false, showAddModel: false,errorMsg:"" ,dateValue:null});
				this.getUpcomingAppointments();
				this.getAppointmentDetails(responseJson.data.id, "view")
			}else if(responseJson.errors[0] === "Availability date can't be blank"){
						this.setState({ loadingData: false,errorMsg:"",dateValue:null});
			}
			else if(responseJson.errors[0] === "Start time must be in the future"){
				this.setState({ loadingData: false,startErrorMsg:responseJson.errors[0],dateValue:null});
	}
			else if(responseJson.errors[0] === "Title can only contain alphabets and spaces"){
				this.setState({ loadingData: false,errorMsg:responseJson.errors[0],dateValue:null,startErrorMsg:null,titleError:true,endDateTime:false});
	}
			else{
				
				this.setState({ loadingData: false,errorMsg:responseJson.errors,startErrorMsg:null,dateValue:null});
			}
		
			
		}
	}
	async receiveUpcomingAppointment(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetUpcomingAppointmentsFormCallId &&
			this.apiGetUpcomingAppointmentsFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({pageLoad:false})

			if(responseJson && responseJson.data){
				const upcomingList = responseJson.data.filter((item: any) =>
					item.attributes.status.toLowerCase() === "open"
				);
				const upcomingListDate = upcomingList.map((element: any) => element.attributes.availability_date);
				const validAppointmentsCount = upcomingList.filter((item: any) => {
					const [day, month, year] = item.attributes.availability_date.split('/');
					const appointmentDate = new Date(year, month - 1, day);
				
					const currentDate = new Date();
					appointmentDate.setHours(0, 0, 0, 0);
					currentDate.setHours(0, 0, 0, 0);
				
					return appointmentDate >= currentDate;
				  }).length;
				this.setState({ pageLoad:false ,upcomingAppointments: upcomingList, upcomingAppointmentsCount: validAppointmentsCount || 0, upcomingDateList: upcomingListDate });
	
				if (!this.state.showCalendar && upcomingList.length > 0) {
					this.getAppointmentDetails(upcomingList[0].id, "view");
				}
			}else if(responseJson.error){
				SuccessMessage(responseJson.error);
			}else if(responseJson.errors){
				SuccessMessage(responseJson.errors[0]);
			}
			

		}
	}
	async receiveGetClientProfileDetails(message:any){
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetClientProfileFormCallId &&
			this.apiGetClientProfileFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({loadingData:false});
			if(responseJson.client_details){
				localStorage.setItem("clientName", responseJson.client_details.attributes.full_name);
				localStorage.setItem("caseID", responseJson.client_details.id);
				this.getUpcomingAppointments();
			}
			
		}
	}
	async receiveUpdateAppointment(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiUpdateAppointmentsFormCallId &&
			this.apiUpdateAppointmentsFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.data){
				this.setState({ loadingData: false, showUpdateModel: false ,errorMsg:"" });
				SuccessMessage("Appointment Updated successfully");
				this.getUpcomingAppointments();
				this.getAppointmentDetails(responseJson.data.id, "view")
			}else if(responseJson.error){
				this.setState({ loadingData: false,errorMsg:responseJson.error});
			}else{
				this.setState({ loadingData: false,errorMsg:responseJson.errors});
			}

		}
	}
	async receiveDeleteAppointment(message: any) {
		if ( 
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiDeleteAppointmentsFormCallId && 
			this.apiDeleteAppointmentsFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
 
			let responseJson = message.getData( 
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.message){
				this.setState({ deleteLoad: false, showDeleteModel: false }) 
				SuccessMessage(responseJson.message); 
				this.getUpcomingAppointments();
			}else{
				this.setState({ deleteLoad: false, showDeleteModel: false })
				FailedToastCenter(responseJson.errors)
			}
		}
	}
	async receiveAppointmentDetails(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetAppointmentDetailsFormCallId &&
			this.apiGetAppointmentDetailsFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			const date = moment(responseJson.data.attributes.availability_date, "DD MM YYYY").format("DD MMM YYYY");
			const startTime = responseJson.data.attributes.start_time.slice(0, 5);
			const endTime = responseJson.data.attributes.end_time.slice(0, 5);

			this.setState({
				deleteLoad: false,
				appointmentID: responseJson.data.attributes.id,
				name: responseJson.data.attributes.name,
				title: responseJson.data.attributes.title,
				location: responseJson.data.attributes.location,
				purpose: responseJson.data.attributes.purpose,
				information: responseJson.data.attributes.additional_information,
				appointmentType: responseJson.data.attributes.type_of_appointment,
				date: moment(date, 'DD MMM YYYY'), startTime: moment(startTime, 'HH:mm'), endTime:moment(endTime, 'HH:mm') ,
				calenderDate:moment(responseJson.data.attributes.availability_date,"DD MM YYYY").toDate(),
				currentdate:moment(responseJson.data.attributes.availability_date,"DD MM YYYY"),
				prevDate:moment(responseJson.data.attributes.availability_date,"DD MM YYYY").toDate(),

			});
			if (this.state.status === 'edit') {
				this.setState({errorMsg:"", showUpdateModel: true });
			} else {
				this.setState({ showCalendar: true, showUpdateModel: false });
			}

		}
	}
	
	setCalendarDate = (date:any) => {
		const newDate=moment(date).add(1, 'days').toDate();
		this.setState({
			calenderDate: newDate,
		})
	}
	

	ChangeBackwardDate = (date:any) => {
		const day = date.getUTCDate();
	
			const newDate=moment(date).subtract(1, 'days').toDate();
			this.setState({
				calenderDate: newDate,
			})
	
	}
	getAppointments = () => {
		const token = localStorage.getItem("token");
		this.setState({ loadingData: true })
		const header = {
			"token": token
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetAppointmentsCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getCasesApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};
	getAppointmentsSolicitor = () => {
		const token = localStorage.getItem("token");
		this.setState({ loadingData: true })
		const header = {
			"token": token
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetSolicitorAppointmentsFormCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getSolicitorCase
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};
	

	addAppointment(values: AddAppointmentValues): boolean {
		const caseID = localStorage.getItem("caseID");
		const token = localStorage.getItem("token");
		this.setState({ loadingData: true });
		const header = {
			"Content-Type": configJSON.appointmentApiContentType,
			"token": token
		};
		const httpBody = {
			"case_id": caseID,
			"name": values.name,
			"availability_date":this.state.dateValue,
			"start_time": values.starttime && values.starttime.format("HH:mm"),
			"end_time": values.endtime?.format("HH:mm"),
			"title": values.title,
			"type_of_appointment": values.type,
			"location": values.location,
			"purpose": values.purpose,
			"additional_information": values.information
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiAddAppontPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.addAppointmentApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.addAppointmentAPiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};
	getUpcomingAppointments = () => {
		const caseID = localStorage.getItem("caseID");
		const userType=localStorage.getItem('userType');
		const token = localStorage.getItem("token");
		this.setState({pageLoad:true})
		const header = {
			"token": token
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetUpcomingAppointmentsFormCallId = requestMessage.messageId;

		// if(userType === 'client'){
		// 	requestMessage.addData(
		// 		getName(MessageEnum.RestAPIResponceEndPointMessage),
		// 		configJSON.getClientUpcomingAppointment
		// 	);
		// }else{
			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.getUpcomingAppointment}?case_id=${caseID}`
			);
		// }
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};
	
	deleteAppointments = (appointmentId: any) => {
		this.setState({ deleteLoad: true });
		const token = localStorage.getItem("token");
		const header = {
			"token": token
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiDeleteAppointmentsFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getDeleteAppointment}${appointmentId}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.deleteAllAppointmentsAPiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};
	updateAppointment(values: AddAppointmentValues) {
		const token = localStorage.getItem("token");
		this.setState({ loadingData: true });
		const header = {
			"Content-Type": configJSON.appointmentApiContentType,
			"token": token
		};

		const httpBody = {
			"name": values.name,
			"availability_date": moment(this.state.dateEditValue || this.state.date, "DD MMM YYYY").format("DD/MM/YYYY"),
			"start_time": values.starttime?.format("HH:mm"),
			"end_time": values.endtime?.format("HH:mm"),
			"title": values.title,
			"type_of_appointment": values.type,
			"location": values.location,
			"purpose": values.purpose,
			"additional_information": values.information
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiUpdateAppointmentsFormCallId = requestMessage.messageId;
		let appointmentPath;
		if(localStorage.getItem("userType")==="solicitor" || this.state.userType === "solicitor" || localStorage.getItem("userType")==="guest" || this.state.userType === "guest" ){
			appointmentPath=`${configJSON.updateSolicitorCase}${this.state.appointmentID}${configJSON.updateSolicitorCase2}`
		}
		else{
			appointmentPath= `${configJSON.getDeleteAppointment}${this.state.appointmentID}`
		}

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			appointmentPath
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.updateAppointmentAPiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};
	
	getClientProfile = () => {
		this.setState({ loadingData: true });
		const token = localStorage.getItem('token');
		const header = {
			"Content-Type": configJSON.appointmentApiContentType,
			"token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetClientProfileFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.clientProfileAPiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};
	getAppointmentDetails = (id: any, status: string) => {
		this.setState({ deleteLoad: true, status: status, filterType: "" });
		const token = localStorage.getItem("token");
		const header = {
			"token": token
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetAppointmentDetailsFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getDeleteAppointment}${id}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};
	handle_Search = (e: React.ChangeEvent<HTMLInputElement>) => {
		const search_Text = e.target.value.toLowerCase();
		const filtered_Data = this.state.appointmentData.filter((item: any) =>
			item.attributes.full_name.toLowerCase().includes(search_Text)
		);

		this.setState({ filteredAppointmentData: filtered_Data, filterStatus: true });
	};
	getDataSource = () => {
		if (this.state.filterStatus) {
			return this.state.filteredAppointmentData
		} else {
			return this.state.appointmentData
		}
	}
	getConvertDate = (date: string) => {
		return moment(date, "DD MM YYYY").format("D MMM");
	}
	hideModel = () => {
		this.setState({errorMsg:"",showAddModel: false ,});
	}
	showModel = () => {
		this.setState({errorMsg:"", showAddModel: true })
	}
	hideModel1 = () => {
		this.setState({ errorMsg:"",showUpdateModel: false });
	}
	saveData = (values: any) => {
		this.addAppointment(values);
	};
	updateData = (values:any) => {
		this.updateAppointment(values);
	};
	goToDetailsScreen = (rowData: any) => {
		const goToDetails = new Message(getName(MessageEnum.NavigationMessage));
		goToDetails.addData(getName(MessageEnum.NavigationTargetMessage), "AppointmentDashboardDetails");

		goToDetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		const raiseMessage: Message = new Message(
			getName(MessageEnum.NavigationPayLoadMessage)
		);
		raiseMessage.addData("rowData", rowData);
		goToDetails.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

		this.send(goToDetails);
	}
	onDateChange = (value: any) => {
		this.setState({ filterType: "", calenderDate: value, prevDate: value })
	};
	getAddedBy = (fullName: string) => {
		const words = fullName.split(" ");

		for (let i = 0; i < words.length; i++) {
			if(words[i][0]){
			words[i] = words[i][0].toUpperCase() + words[i].substring(1);
			}
		}
		return words.join(" ");
	}
	getTypeText = (type: string) => {
		if (type === "in_person") {
			return "In-Person"
		} else {
			return "Online"
		}
	}
	updateShowDetailsIndex = (indexId: any, aptRowIndex: any) => {
		if (this.state.showDetailsIndex === indexId) {
			this.setState({
				showDetailsIndex: "",
				activeAptRowIndex: ""
			})
		}
		else {
			this.setState({
				showDetailsIndex: indexId,
				activeAptRowIndex: aptRowIndex
			})
		}
	
	
	}
	customNameValidator = (_: any, value: string) => {
		if (!value || !/^[a-zA-Z\s]*$/.test(value)) {
		  return Promise.reject('Please enter a valid name');
		}
		return Promise.resolve();
	};  
	onFilterChange = (type: any) => {
		
		if(type.target.checked){
			this.setState({ filterType: type.target.value });
		}else{
			this.setState({ filterType: "" });
		}
	}
	getHeight = (aptRowIndex: any) => {
		if (this.state.activeAptRowIndex === aptRowIndex) { return "auto" } else { return "60px" };
	}
	showDeleteModel = (id: any) => {
		this.setState({ showDeleteModel: true, appointmentID: id })
	}
	hideDeleteModel = () => {
		this.setState({ showDeleteModel: false, appointmentID: 0 })
	}
	handledatepicker = (current:any) => {
		return moment().add(-1, 'days') >= current
	}
	handlePopupContainer = (trigger: any) => {
		// this.setState({errorMsg:""})
		return trigger.parentElement
	}
	previousmonth = () => {

		const date = this.state.calenderDate ? this.state.calenderDate :this.state.newDate 
        const datenewDisplaye= moment(date).subtract(1, 'month').toDate()
		this.setState({calenderDate:datenewDisplaye})
			if (moment(datenewDisplaye).isSame(this.state.prevDate, 'month')) {
                this.setState({ calenderDate: this.state.prevDate });
            }
            else{
                this.setState({ calenderDate: null , newDate: datenewDisplaye });
            }
    }

    nextmonth = () => {
        const date = this.state.calenderDate ? this.state.calenderDate :this.state.newDate 
        const datenewDisplaye=  moment(date).add(1, 'month').toDate();
		this.setState({calenderDate:datenewDisplaye})
			if (moment(datenewDisplaye).isSame(this.state.prevDate, 'month')) {
                this.setState({ calenderDate: this.state.prevDate });
            }
            else{
                this.setState({ calenderDate: null , newDate: datenewDisplaye });
            }
    }
	getRefValue=(isFirstAppointment: boolean , firstAppointmentRef:RefObject<HTMLDivElement>)=>{
		if(isFirstAppointment){
			return firstAppointmentRef
		}
		else{
			return null
		}
	}

	checkDate =()=>{
		this.state.dateValue === null &&
			this.setState({dateError:"Please select date"})
		
	}
	cancelImage = (formRef:any)=>{
		this.setState({dateValue:null,dateError:null,startErrorMsg:null})
		formRef.current.resetFields()
		this.hideModel()
	}
	handleStartDate = ()=>{
		this.setState({startErrorMsg:null})
	}
	
}
// Customizable Area End
