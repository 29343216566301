// Customizable Area Start
//@ts-ignore
//@ts-nocheck

import React from "react";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";

interface TimerVerifyControllerProps {
  seconds: string;
  minutes: string;
  resendFlag: boolean;
  updateResendFlag: string;
  decrementTimer: string;
}
class TimerVerify extends EmailAccountRegistrationController {
  private timerInterval: number;
  constructor(props: TimerVerifyControllerProps) {
    super(props);
  }
  async componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
    
  }
  async componentDidUpdate(preProps:any) {
    
    if (this.props.seconds == 0 && this.props.minutes == 0 && !this.props.resendFlag) {
      this.props.updateResendFlag();
      this.timerFlag(this.props.resendFlag)
    }
   
    this.timerProps(preProps.minutes,this.props.minutes)

    if(this.props.minutes === 0 && this.props.seconds === 0){
      this.stopTimer()
    }
    localStorage.setItem("timer",JSON.stringify({minutes:this.props.minutes,seconds:this.props.seconds}))
   
  }
  timerFlag = (flag)=>{
    if(!flag){
      this.startTimer();
    }

  }
  timerProps = (prevProps,props)=>{
    if (prevProps !== props && props === 1) {
      this.startTimer();
    }

  }

  startTimer = () => {
    this.timerInterval = setInterval(() => {
      const { minutes, seconds } = this.props;
      if (minutes == 0 && seconds == 0) {
        this.stopTimer();
      } else {
        if (seconds !== 0) {
          this.props.decrementTimer();
        }
       
      }
    }, 1000);
  };

  stopTimer = () => {
    clearInterval(this.timerInterval);
  };

  render() {
    const { minutes, seconds } = this.props;
    const timerDisplay = `${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;

    return <div className="timer">{timerDisplay}</div>;
  }
}

export default TimerVerify;
// Customizable Area End
