// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import UserLibraryController from '../../blocks/catalogue/src/UserLibraryController.web';
import PageWrapper from './Pagewrapper.web';

class CreateEntry extends UserLibraryController {
    
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}
	render(): React.ReactNode {
        if(this.state.redirectToEntriesScreen){
            window.history.back();
        }
		return (
            <PageWrapper title = "Add Entry" hasBack>
                <div className='create-case-management-content'>
                    <div data-test-id="add-form">
                        <Form
                            ref={this.formRef}
                            layout="vertical"
                            initialValues={{}}
                            className="form"
                            style={{ padding: "20px" }}
                        >
                            <Row gutter={12}>
                                <Col xs={8}>
                                    <Form.Item
                                        label="Title"
                                        name="title"
                                        className="bold-label"
                                        rules={[
                                            
                                            {
                                                validator: (_, value) => {
                                                    if (!value || value.trim() === '' || value.includes('  '))  {
                                                        return Promise.reject('Please enter title');
                                                    }
                                                    return Promise.resolve(); 
                                                }
                                            }
                                          ]}
                                    >
                                        <Input maxLength={20} placeholder='Title of Entry' />
                                    </Form.Item>
                                </Col>
                                <Col xs={8}>
                                <Form.Item
                                    label="Date"
                                    name="entry_date"
                                    className="bold-label"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select date"
                                        },
                                    ]}

							    >

                                    <DatePicker
                                        placeholder='Date of Entry'
                                        id="date"
                                        className="font-style time-design"
                                        format="DD MMM YYYY"
                                        picker="date"
                                        inputReadOnly={true}
                                    />
							    </Form.Item>
                                </Col>
                                
                                <Col xs={24}>
                                    <Form.Item
                                        label="Entry"
                                        name="entry"
                                        className="bold-label"
                                        style={{ marginBottom: 0 }}
                                        rules={[
                                            
                                            {
                                                validator: (_, value) => {
                                                    if (!value || value.trim() === '' || value.includes('  '))  {
                                                        return Promise.reject('Please enter note');
                                                    }
                                                    return Promise.resolve(); 
                                                }
                                            }
                                          ]}
                                    >
                                        <Input.TextArea style={{ height: '452px', resize: 'none' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="c-divider"></div>
                                <Row justify="end" style={{ marginTop: '20px' }}>
                                    <Col>
                                    <a data-test-id="cancel-btn" onClick={() => window.history.back()} style={{ color: '#830065', fontSize: '14px', fontWeight: '700', marginRight: '30px' }}>Cancel</a>
                                        <Button
                                            data-test-id="create-entry"
                                            onClick={() => {
                                                let hasErrors = false;
                                                const values = this.formRef.current.getFieldsValue();
                                                if (!values.title || !values.entry || !values.entry_date) {
                                                    hasErrors = true;
                                                }
                                                if(!hasErrors)
                                                this.createRehabJournal(this.getUrlParameter("folderType"), this.getUrlParameter("clientId"), this.formRef.current.getFieldsValue().title, this.formRef.current.getFieldsValue().entry, this.formRef.current.getFieldsValue().entry_date)
                                                
                                            }}
                                            htmlType="submit"
                                            type="primary"
                                            style={{
                                                background: "#830065",
                                                borderColor: "#830065",
                                                borderRadius: "8px",
                                                marginBottom: "20px",
                                                height: "56px",
                                                width: '150px',
                                                color: "white",
                                                fontWeight: "bold",
                                                marginRight: '20px'
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                        </Form>
                    </div>
                </div>
		    </PageWrapper>
           
		);
	}
}

export default CreateEntry;
// Customizable Area End