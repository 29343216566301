// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import { Button, Col, Divider, Form, Input, Row } from "antd";
import UserLibraryController from './UserLibraryController.web';
import PageWrapper from '../../../components/src/Pagewrapper.web';

class CreateCaseManagement extends UserLibraryController {
    
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}
    navigateBack = () => {
        window.history.back();
      }
	render(): React.ReactNode {
		return (
            <PageWrapper title = "Case Management" hasBack>
                <div className='create-case-management-content'>
                    <div data-test-id="add-form">
                        <Form
                            ref={this.formRef}
                            layout="vertical"
                            initialValues={{}}
                            className="form"
                            style={{ padding: "20px" }}
                        >
                            <Row gutter={12}>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item
                                        label="Title"
                                        name="title"
                                        className="bold-label"
                                    >
                                        <Input maxLength={20} placeholder='Name of note' />
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={24}>
                                    <Form.Item
                                        label="Note"
                                        name="entry"
                                        className="bold-label"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Input.TextArea style={{ height: '452px', resize: 'none' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="c-divider"></div>
                                <Row justify="end" style={{ marginTop: '20px' }}>
                                    <Col>
                                    <a data-test-id="cancel-btn" onClick={() => window.history.back()} style={{ color: '#830065', fontSize: '14px', fontWeight: '700', marginRight: '30px' }}>Cancel</a>
                                        <Button
                                            data-test-id="create-case"
                                            onClick={() => {
                                                this.createFile(this.getUrlParameter("folderType"), this.getUrlParameter("id"), null,this.formRef.current.getFieldsValue().title, this.formRef.current.getFieldsValue().entry)
                                                this.navigateBack()
                                            }}
                                            htmlType="submit"
                                            type="primary"
                                            style={{
                                                background: "#830065",
                                                borderColor: "#830065",
                                                borderRadius: "8px",
                                                marginBottom: "20px",
                                                height: "56px",
                                                width: '150px',
                                                color: "white",
                                                fontWeight: "bold",
                                                marginRight: '20px'
                                            }}
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                </Row>
                        </Form>
                    </div>
                </div>
		    </PageWrapper>
           
		);
	}
}

export default CreateCaseManagement;
// Customizable Area End