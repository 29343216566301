// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import './DefaultLayout.css';
import React from 'react';
import { Button, Col, Form, Input, Row, Space, Tooltip } from "antd";
import EmailAccountLoginController from '../../blocks/email-account-registration/src/EmailAccountRegistrationController.web';
import PageWrapper from './Pagewrapper.web';
import { InfoCircleOutlined } from '@ant-design/icons';

class ChangePassword extends EmailAccountLoginController {
	constructor(props: any) {
		super(props);
		this.formRef = React.createRef<FormInstance>();
	}

	render(): React.ReactNode {
		const toolipText = (
			<div style={{maxWidth: "500px"}}>
				<ul>
					<li>At least one capital letter</li>
					<li>At least one lowercase letter</li>
					<li>At least one number</li>
					<li>Minimum character length is 8 characters</li>
				</ul>
			</div>

		);
		return (
			<PageWrapper title = "Change Password" hasBack>
				
			<div className='change-password-content'>
			<p className="change-password-text">Change Password</p>
				<div className='case-form-div'>
					<Form
						ref={this.formRef}
						layout="vertical"
						initialValues={{}}
						className="form"
						style={{ padding: "20px" }}
                        >
						  	<Row gutter={12}>
							  	<Col xs={24}>
									<Form.Item
										className="bold-label"
										label="Current Password"
										name="current_password"
										rules={[{validator: this.passwordValidation , required:true }]}
									>
										<Input.Password  className={this.state.changePasswordErrorMessage ? "input-error" : ""}  placeholder="Enter old password" />
									</Form.Item>
									{this.state.changePasswordErrorMessage ==='incorrect password' && <p data-test-id="changePassErrorMsg" className="case-error-msg" style={{color: '#DC2626', textAlign: 'left'}}>{this.state.changePasswordErrorMessage}</p>}
								</Col>
								<Col xs={24}>
									<Form.Item
										className="bold-label"
										label="New Password"
										name="password_confirmation"
										rules={[{validator: this.passwordValidation}]}
									>
										<Input.Password placeholder="Enter new password" className={this.state.confirmpassError ? "input-error" : ""} />
									</Form.Item>
									{this.state.confirmpassError ==='Your new password must be different from your current password' && <p data-test-id="samePassword" className="case-error-msg" style={{color: '#DC2626', textAlign: 'left'}}>{this.state.confirmpassError}</p>}
									<div className="toolip">
										<span style={{fontSize: '12px', fontWeight: '400', color: '#0F172A'}}>
											Password instructions
										</span>&nbsp;
										<Tooltip placement="bottomLeft" title={toolipText} color="white">
											<InfoCircleOutlined style={{color: "#830065"}} />
										</Tooltip>
									</div>
								</Col>
								<Col xs={24}>
									<Form.Item
										className="bold-label"
										label="Confirm New Password"
										name="new_password"
										dependencies={['password_confirmation']}
										onPaste={(e) => e.preventDefault()}
										rules={[({ getFieldValue }) => ({validator: (_: any, value: string) => this.confirmPasswordValidator(value, getFieldValue('password_confirmation'))})]}
									>
										<Input.Password placeholder="Confirm new password" className={this.state.confirmpassError ? "input-error" : ""} />
									</Form.Item>
									{this.state.changePasswordErrorMessage === 'New password and password confirmation are required' && <p data-test-id="changePassErrorMsg" className="case-error-msg" style={{color: '#DC2626', textAlign: 'left'}}></p>}

								</Col>
								<Form.Item>
									<Button 
										type="text"
										style={{
											color: "#830065",
											fontSize: "14px",
											fontWeight: "700",
											marginRight: '14px',
											height: "32px",
											width: "75px",
											textAlign: "left",
											marginLeft: '-7px',
											marginTop: '10px'
											}}
											onClick={() => window.history.back()}>Cancel
									</Button>
									<Button
										data-test-id="change_button"
										onClick={() => this.onChangePassword(this.formRef.current.getFieldsValue())}
										htmlType="submit"
										type="primary"
										loading={this.state.loadingChangePassword}
										style={{
											marginTop: '10px',
											background: "#830065",
											borderColor: "#830065",
											borderRadius: "8px",
											height: "32px",
											width: "108px",
											color: "white",
											fontWeight: "bold",
										}}
									>
										Save
									</Button>
								</Form.Item>
								
                            	</Row>
					</Form>
				</div>
			</div>
		</PageWrapper>
		);
	}
}

export default ChangePassword;
// Customizable Area End