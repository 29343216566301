// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from "react";
import rehab_image from "../assets/rehab.png";
import banner_image from "../assets/image.png";
import setting from "../assets/setting.png";
import back from "../assets/back.png";
import cancel from "../assets/cancel.png";
import up from "../assets/up.png";
import down from "../assets/down.png";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Spin, Tooltip, Collapse, Switch } from "antd";
import "./EmailAccountRegistration.css";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
import { Link, Redirect } from "react-router-dom";
import { InfoCircleOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
import _ from 'lodash';

class EmailAccountRegistration extends EmailAccountRegistrationController {
	constructor(props: any) {
		super(props);
	}
	async componentDidMount() {
		this.showAccount(this.getUrlParameter("id"), this.getUrlParameter("token"));
		this.showCookiesModal();
		this.generateDeviceId();
	}
	genExtra(key) {
		return (
			<div id={`swtich-div${key}`} style={{ display: 'flex', gap: '20px' }} onClick={this.onClickCookieSwitch}>
				{
					this.state.activeKey == key ?
						<img id={`up${key}`} src={up} style={{ height: "20px", width: '20px' }} onClick={() => this.setState({ activeKey: null })} /> :
						<img id={`down${key}`} src={down} style={{ height: "20px", width: '20px' }} onClick={() => this.setState({ activeKey: key })} />
				}
				<Switch id={`switch${key}`} />
			</div>
		)
	}
	renderForm() {
		const toolipText = (
			<div style={{ maxWidth: "600px", borderRadius:'80px' }}>
				<ul>
					<li>At least one capital letter</li>
					<li>At least one lowercase letter</li>
					<li>At least one number</li>
					<li>Minimum character length is 8 characters</li>
				</ul>
			</div>

		);
		return (
			<div id="register" className="centered-content-right">
				<p className="headline-2">Create an account</p>
				<p className="hi-there1">
					Hi <strong>{this.state.full_name ? <span id="full-name" >{_.capitalize(_.head(_.words(this.state.full_name)))}</span> : "there"},</strong> please complete your account
					here
				</p>
				<div className="centered-content-right2">
							<Form
								layout="vertical"
								initialValues={{
									full_name: this.state.full_name,
									email: this.state.email,
									full_phone_number: this.state.full_phone_number
								}}
								onFinish={this.onFinish}
								className="form"
								style={{padding:'0 20px 0 20px'}}
							>
								<Row gutter={12}>
									<Col xs={24}>
										<Form.Item
											label="Full name"
											name="full_name"
											className={this.state.full_name 
												? "disable-label" : "bold-label"
											}
										>
											<Input disabled={this.state.full_name}  className="fullname"/>
										</Form.Item>
									</Col>

									<Col xs={24}>
										<Form.Item
											label="Email"
											name="email"
											className={this.state.email ? "disable-label" : "bold-label"}
										>
											<Input disabled={this.state.email} />
										</Form.Item>
									</Col>
									<Col xs={24}>
										<Form.Item
											name="full_phone_number"
											label="Mobile"
											className={this.state.full_phone_number ? "disable-label" : "bold-label"}
										>
											<Input disabled={this.state.full_phone_number} />
										</Form.Item>
									</Col>
									<Col xs={24}>
										<Form.Item
											className="bold-label"
											label="Password"
											name="confirmPassword"
											rules={[{ validator: this.passwordValidation  ,}]}
										>
											<Input.Password placeholder="Enter your password" className="specific-input2" />
										</Form.Item>
										<div className="toolip">
											<span style={{ fontSize: '12px',
											 fontWeight: '400',
											  color: '#0F172A' }}>
												Password instructions
											</span>&nbsp;
											<Tooltip placement="bottomLeft" title={toolipText} color="white">
												<InfoCircleOutlined style={{ color: "#830065" }} />
											</Tooltip>
										</div>
									</Col>
									<Col xs={24}>
										<Form.Item
											className="bold-label"
											label="Confirm Password"
											name="password"
											dependencies={['confirmPassword']}
											onPaste={(e) => e.preventDefault()}
											rules={[({ getFieldValue }) => ({ validator: (_: any, value: string) => this.confirmPasswordValidator(value, getFieldValue('confirmPassword')), required:'true' , message:'Enter your password'})]}
										>
											<Input.Password placeholder="Confirm your password" className="specific-input2" />
										</Form.Item>
									</Col>
									<Col xs={24}>
										<div style={{ display: "flex",
										 textAlign: "left",
										 marginTop:'10px',
										 marginBottom:'10px'}}>
											<Checkbox
											style={{borderRadius:'8px !important'}}
												id="tc"
												checked={this.state.acceptTermsAndPrivacy}
												onChange={(e) =>
													this.setState({
														acceptTermsAndPrivacy:
															e.target.checked,
														tncApiErrorMessage: ''
													})
												}
											/>
											&nbsp;&nbsp;
											<p style={{ fontSize: '14px',
											 fontWeight: '400',
											  color: '#0F172A' }}>
												I agree with&nbsp;
												<a
													id="tc-anchor-p"
													style={{ color: "#830065" }}
													onClick={() => this.showPrivacyModal()}
												>
													<b style={{ color: "#830065" }}>
														Privacy Policy
													</b>
												</a>
												&nbsp; and &nbsp;
												<a
													id="tc-anchor"
													style={{ color: "#830065" }}
													onClick={() => this.showTnCModal()}
												>
													<b style={{ color: "#830065" }}>
														Terms and Conditions
													</b>
												</a>
												&nbsp;
											</p>
										</div>
									</Col>

									<Col xs={24}>
										<>
										<Button
											id="submit-button"
											htmlType="submit"
											type="primary"
											loading={this.state.loadingSignup}
											style={{
												background: "#830065",borderColor: "#830065",
												borderRadius: "5px",marginBottom: "10px",
												height: "56px",width: "100%",
												color: "white",fontWeight: "bold",
											}}
										>
											Create
										</Button>
										<div style={{ display: "flex",margin:'15px 0px' }}>
										<p style={{ fontSize: '16px',
										 fontWeight: '400',
										  color: '#0F172A' }}>Already have an account?</p>&nbsp;
										<Link to="/login">
											<a
												id="login-btn"
												style={{
													color: "#830065",
													fontSize: '16px',
													fontWeight: '400',

												}}
											>
												<b>Login</b>
											</a>
										</Link>
									</div>
										</>
									</Col>
					
								</Row>
							</Form>
				</div>

			</div>
		)
	}
	renderModalsForError() {
		return (
			<>
				{/* @ts-ignore */}
				<Modal
					className="cookies-modal"
					id="cookies-modal"
					open={this.state.showErrorModel}
					onCancel={this.hideErrorModel}
					footer={
						<div style={{ display: "flex", justifyContent: "right", padding: '0 22px 22px 22px' }} >
							
							<Button
								style={{
									height: "56px",
									width: "120px",
									background: "#830065",
									borderColor: "#830065",
									borderRadius: "8px",
									color: "white",
									fontSize: '16px',
									fontWeight: '700'
								}}
								onClick={this.hideErrorModel} >Okay</Button>
						</div>
					}
				>
					<div style={{ padding: '40px 25px 20px 25px' ,fontSize: '16px',
									fontWeight: '500',marginTop:'10px'}}>
						Your account is not created.Please contact <b><font color="#830065"> admin@rehabtracker.com </font> </b> for any further information.
					</div>
					
				</Modal>
			
				
				
				{/* @ts-ignore */}
			</>
		);
	}
	renderModals() {
		return (
			<>
			 {/* @ts-ignore */}
			 <Modal 
				className="cookies-modal"
				id="cookies-modal"
				title={<h1 className="cookies-header">Proclaim Group Cookies</h1>}
				open={!this.state.cookies_status && !this.state.loadingSignupData && this.state.showCookies}
				closable={false} 
				centered
				footer={
					<div style={{display: "flex",justifyContent: "right", padding: '22px'}} >
						<Button 
							type="text"
								style={{
									color: "#64748B",
									fontSize: "16px",
									fontWeight: "700",
									marginTop: '10px'
								}}
							onClick={this.showCookiesHandleCancel} >Reject</Button>
						<Button 
							style={{
								height: "56px",
								width: "120px",
								background: "#830065",
								borderColor: "#830065",
								borderRadius: "8px",
								color: "white",
								fontSize: '16px',
								fontWeight: '700'
							}}
							onClick={this.showCookiesHandleCancel} >Accept all</Button>
					</div>
				}
				>
					<div style={{ padding: '25px' }}>
						Rehab Tracker (subsidiary of Proclaim Group) respects your privacy and is committed to protecting your personal data. This policy will inform you as to how we use cookies, what they do and how you can revise the settings.
					</div>
					<div id="cookies-setting-div" style={{ display: "flex", marginLeft: "25px", cursor: 'pointer', }} onClick={() => this.showCookiesSettingsModal()}>
						<img src={setting} style={{ height: '24px', width: '24px' }} />&nbsp;&nbsp;
						<p className="go-cookies">Go to cookie settings</p>
					</div>
			</Modal> 
			<Modal 
				className="cookies-modal"
				id="show-cookies-modal"
				open={this.state.showCookiesSettings}
				closable={false}
				width={1000}
				footer={null}
				centered
				>
					<div style={{ display: "flex", }}>
						<div style={{ width: '40%' }} className="policy-left-div">
							<div className="left-header-title">
								<img id="back" onClick={() => this.showCookiesModal()} src={back} className="back-image" />
								<p className="cookies-setting-header"> Cookies Settings</p>
							</div>
							<Divider style={{ backgroundColor: '#E2E8F0' }} />
							<div
								id="controller-cookies"
								style={{
									cursor: 'pointer',
									padding: '16px',
									borderRadius: '8px',
									fontSize: '16px',
									fontWeight: '700',
									width: '72%',
									marginBottom: '10px',
									margin: '10px 0px 10px 15px',
									background: this.state.settingModalContent === 'Controller' ? '#ecd9e8' : '#fff',
									color: this.state.settingModalContent === 'Controller' ? '#830065' : '#64748B',
								}}
								onClick={() => this.setState({ settingModalContent: "Controller" })}
							>
								Controller
							</div>
							<div
								id="proclaim-setting"
								style={{
									cursor: 'pointer',
									padding: '16px',
									borderRadius: '8px',
									width: '72%',
									fontSize: '16px',
									fontWeight: '700',
									margin: '10px 0px 10px 15px',
									background: this.state.settingModalContent === 'Proclaim Group' ? '#ecd9e8' : '#fff',
									color: this.state.settingModalContent === 'Proclaim Group' ? '#830065' : '#64748B',
								}}
								onClick={() => this.setState({ settingModalContent: "Proclaim Group" })}
							>
								Proclaim Group Cookie Policy
							</div>

						</div>
						{/* Right Column */}
						<div style={{ width: '60%', marginLeft: '8px' }}>

							{this.state.settingModalContent === 'Controller' && (
								<>
								<div style={{display: "flex", alignItems: 'center'}}>
									<p className="policy-title"> CONTROLLER</p>
								</div>
								<Divider />
								<div className="policy-content" dangerouslySetInnerHTML={{__html: this.getCookieData("controller")}}></div>
								<Divider />
								<Button 
									id="accept-controller"
									style={{
										height: "56px",
										width: "120px",
										background: "#830065",
										borderColor: "#830065",
										borderRadius: "8px",
										color: "white",
										fontSize: '16px',
										fontWeight: '700',
										position: 'absolute',
										bottom: '22px',
										right: '22px',
							}}
							onClick={() => this.onClick()} >Accept all</Button>
								</>
							)}
							{this.state.settingModalContent === 'Proclaim Group' && (
								<>
									<div style={{ display: "flex", alignItems: 'center' }}>
										<p className="policy-title">
											PROCLAIM GROUP COOKIE POLICY
										</p>
									</div>
									<Divider />

									<p id="cookieMessage" className="policy-content-2">Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
									<p id="cookieMessage1" className="policy-content-3">A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.</p>
									<p id="cookieMessage2" className="policy-content-4">We use the following cookies:</p>

										<Collapse
											id="collapse"
											activeKey={this.state.activeKey}
											expandIconPosition="end"
											onChange={(e) => {
												this.setState({activeKey: e[e.length - 1]})
											}}
										>
											<Panel
												showArrow= {false}
												header={<p className="panel-header" >Strictly necessary cookies</p> }
												key="1" 
												extra={this.genExtra(1)}
											>
												<div className="panel-content" dangerouslySetInnerHTML={{__html: this.getCookieData("necessary")}}></div>
											</Panel>
											<Panel 
												showArrow= {false} 
												header={<p className="panel-header" >Analytical or performance cookies</p> }
												key="2" 
												extra={this.genExtra(2)}
											>
												<div className="panel-content" dangerouslySetInnerHTML={{__html: this.getCookieData("analytical")}}></div>
											</Panel>
											<Panel 
												showArrow= {false} 
												header={<p className="panel-header" >Functionality cookies </p>}
												key="3" 
												extra={this.genExtra(3)}
											>
											<div className="panel-content" dangerouslySetInnerHTML={{__html: this.getCookieData("functional")}}></div>
											</Panel>
										</Collapse>
										<Divider />
										<Button
											id="save-accept-button"
											style={{
												height: "56px",
												width: "255px",
												background: "#830065",
												borderColor: "#830065",
												borderRadius: "8px",
												color: "white",
												fontSize: '16px',
												fontWeight: '700',
												position: 'absolute',
												bottom: '20px',
												right: '37px',
									}}
									onClick={() => this.onClick()} >Save & Accept Settings</Button>
								</>

							)}

						</div>
					</div>

				</Modal>
				<Modal
					className="privacy-modal"
					id="tncModal"
					title={<h1 className="center-header">Terms and Conditions</h1>}
					open={this.state.visibleForTnC}
					onCancel={this.tncHandleCancel}
					footer={<Button
						style={{
							background: "#830065",
							borderColor: "#830065",
							borderRadius: "5px",
							color: "white",
							margin: '20px'
						}}
						onClick={this.tncHandleCancel} >Close</Button>}
					width={1000}
				>
					<div style={{ width: "100%", padding: '20px' }}>
						{this.state.content_type == "terms_and_condition" ? <div id="tncDiv" dangerouslySetInnerHTML={{ __html: this.state.content_description }}></div> : <></>}
					</div>
				</Modal>
				<Modal
					className="privacy-modal"
					id="PrivacyModal"
					title={<h1 className="center-header">Privacy Policy</h1>}
					open={this.state.visibleForPrivacy}
					onCancel={this.privacyHandleCancel}
					footer={<Button
						style={{
							background: "#830065",
							borderColor: "#830065",
							borderRadius: "5px",
							color: "white",
							margin: '20px'
						}}
						onClick={this.privacyHandleCancel} >Close</Button>}
					width={1000}
				>
					<div style={{ width: "100%", padding: '20px' }}>
						{this.state.content_type == "privacy_policy" ? <div dangerouslySetInnerHTML={{ __html: this.state.content_description }}></div> : <></>}
					</div>
				</Modal>
				{/* @ts-ignore */}
			</>
		);
	}
	render(): React.ReactNode {

		if (this.state.redirectToOtp) {
			return (
				<Redirect to='/otp' />
			);
		}
		return (
			<>
			 {
						this.state.loadingSignupData ? 
						<div class="spinContainer">
							<div className="innerSpinContaine">
								<Spin id="spin" />
							</div>
						</div>
						 :
				<Row gutter={12} style={{height:'100%'}}>
					<Col xs={24} sm={12} style={{background: "rgba(251,241,249,255)"}}>
						<div className="centered-content-signup">
							<img style={{
								width: "435px",
								height: "91px", marginBottom: '44px', marginTop: "120px",
							}} src={rehab_image} />
							<img
								style={{ width: "523px", height:"350px",marginBottom: '70px' }}
								src={banner_image}
								alt="rehab"
							/>
							<p className="headline-1" style={{margin:"auto",textAlign:"center",width:"423px",height:"44px",marginBottom:"16px",lineHeight:1 }}>Feature Name</p>

							<p className="hi-there" style={{width:"423px",margin:"auto",textAlign:"center",height:"52px",}}>
								lorem ipsum dolor sit ammet is a dummy text for printing industry
							</p>
						</div>
					</Col>
					<Col xs={24} sm={12}>
						<div className="form-container">
							{this.renderForm()}
							{this.state.tncApiErrorMessage &&
								<div className="tnc-error-div">
									<img id="cancel" onClick={() => this.setState({ tncApiErrorMessage: "" })} src={cancel} style={{ height: '20px', width: '20px', marginRight: '5px', cursor: 'pointer' }} />&nbsp;
									<p style={{ margin: '0px' }}>Please agree to the Privacy Policy and Terms and Conditions to proceed</p>
								</div>}
						</div>
					</Col>
				</Row>
							}

				{this.renderModals()}
				{this.renderModalsForError()}
			</>
		);
	}
}

export default EmailAccountRegistration;
// Customizable Area End
