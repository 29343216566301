Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.loginApiEndPoint = 'bx_block_login/logins';
exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.showAccountsAPiEndPoint = "account_block/accounts";
exports.otpApiEndPoint = "bx_block_login/logins/verify_sms_otp";
exports.settingAPiEndPoint = "bx_block_settings/settings";
exports.getAllCookiesApiEndPoint = 'bx_block_settings/settings/cookies_data?content_type=cookie_policy';
exports.profileAPiEndPoint = 'bx_block_profile/profiles/user_profiles';
exports.updateProfileAPiEndPoint = 'bx_block_profile/profiles';
exports.updateApiMethodType = 'PATCH'
exports.cookiesAcceptAPiEndPoint = 'account_block/accounts/accept_cookies';
exports.apiMethodTypeAddDetail = "POST";
exports.updateApiMethodType = "PATCH";
exports.apiDeleteMethodTypeAddDetail = "DELETE";
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.forgotPasswordAPiEndPoint = 'bx_block_forgot_password/passwords/forgot_password';
exports.resetPasswordAPiEndPoint = 'bx_block_forgot_password/passwords';
exports.changePasswordAPiEndPoint = 'bx_block_forgot_password/passwords/update_password';
exports.validationApiMethodType = "GET";
exports.resendOTPApiEndPoint='bx_block_login/logins/resend_otp';
exports.verifyEmailApiEndPoint='bx_block_profile/profiles/email_update_by_button';
exports.clientProfileAPiEndPoint = 'bx_block_appointment_management/availabilities/client_details';
exports.resetDevieIdAPiEndPoint = "bx_block_login/logins/new_device_login";
exports.createMoodApiEndPoint = "bx_block_dashboard/moods";
exports.putApiMethodType = "PUT";
exports.getNotificationListing = "bx_block_automaticreminders/reminders?page=";
exports.getNotificationListing2 = "&per_page=";
exports.getReadNotification = "bx_block_automaticreminders/reminders/";
exports.updateAllNotification = "bx_block_automaticreminders/reminders/update_all";





// Customizable Area End
