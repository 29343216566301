// Customizable Area Start
export const caseStudy = require("../assets/case-study.png");
export const journal = require("../assets/journal.png");
export const lifestyle = require("../assets/lifestyle.png");
export const medical = require("../assets/medical.png");
export const occupational = require("../assets/occupational.png");
export const phychology = require("../assets/phychology.png");
export const physiotherapy = require("../assets/physiotherapy.png");
export const returnWork = require("../assets/return.png");
export const search = require("../assets/search.png");
export const noData = require("../assets/no-data.png");
export const download = require("../assets/download.png");
export const deleteFile = require("../assets/delete.png");
export const more = require("../assets/more-note.png");
export const dateIcon = require("../assets/calender.png")
export const chevron_left = require("../assets/chevron_left.png");
export const chevron_right = require("../assets/chevron_right.png");
// Customizable Area End