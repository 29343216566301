// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FailedToast, FailedToastCenter, SuccessMessage } from "../../../components/src/Toast";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getStorageData} from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
	navigation: any;
	id: string;
	match: any;
}

 interface Time{ 
	minutes:string | number,
	seconds:string | number
}

interface S {
	acceptTermsAndPrivacy: boolean,
	loadingLogin: boolean,
	visibleForTnC: boolean,
	visibleForPrivacy: boolean,
	content_description: any,
	content_type: any,
	full_name: any,
	full_phone_number: any,
	email: any,
	password: string,
	redirect: boolean,
	digit1: string;
	digit2: string;
	digit3: string;
	digit4: string;
	digit5: string;
	minutes: any,
	seconds: any,
	loadingSignupData: boolean,
	redirectToOtp: boolean,
	redirectToDashboard: boolean,
	loadingOTP: boolean,
	showCookies: boolean,
	showCookiesSettings: boolean,
	settingModalContent: string,
	loginErrorMessage: string,
	tncApiErrorMessage: string,
	otpErrorMessage: string,
	showProfile: boolean,
	activeKey: any,
	analytical_cookie: boolean,
	necessary_cookie: boolean,
	functional_cookie: boolean,
	loadingProfileData: boolean,
	office_phone_no: any,
	loadingUpdateProfile: boolean,
	sub_type: any,
	cookieData: any,
	remember: boolean,
	redirectToResetPassword: boolean,
	loadingForgotPassword: boolean,
	forgotPasswordErrorMessage: string,
	loadingEmailData: boolean
	loadingResetPassword: boolean,
	logoutPopup: boolean,
	resendFlag: boolean,
	content_descriptions: string,
	showErrorModel: boolean,
	resendModel: boolean,
	cookies_status: boolean,
	profileImage?: File | Blob,
	profileImageUrl?: string | null,
	toastMessage?:string,
	showToast?:boolean,
	loadingChangePassword: boolean,
	changePasswordErrorMessage: string,
	confirmpassError: string,
	hobbies: string[],
	showImage: boolean,
	popupVisible: boolean,
	emailChange: boolean,
	verifyEmailPopup: boolean,
	profileUpdateData: any,
	loadingSignup: boolean,
	resetPasswordErrorMessage: string
	deviceId:string,
	loadingCreateMood: boolean,
	showAddMood: boolean,
	selectedMood: string,
	showUpdateMood: boolean,
	loadingUpdateMood: boolean,
	isVisible: boolean,
	notification:boolean,
	showNotifications:any,
	perPage:number,
	moreData:boolean,
	collapsed: boolean,
	isMobile: any
}

interface SS {
	id: any;
}

export default class UserController extends BlockComponent<Props, S, SS> {
	apiResetGetTermsAndConditionFormCallId: string = "";
	apiNotificationCallId :string = "";
	apiReadNotificationCallId:string = "";
	apiReadAllNotificationCallId:string = "";
	apiResetGetPrivacyFormCallId: string = "";
	apiResetGetCookieFormCallId: string = "";
	apiResetGetAllCookieFormCallId: string = "";
	apiResetLoginPostCallId: string = "";
	apiResetShowAccountFormCallId: string = "";
	apiResetSignupPostCallId: string = "";
	apiResetOTPPostCallId: string = "";
	apiResetDevicePostCallId:string= "";
	apiResendOTPPostCallId: string = "";
	apiResetGetProfileFormCallId: string = "";
	apiResetUpdateProfilePostCallId: string = ""
	apiResetGetEmailFormCallId: string = "";
	apiDeleteProfileImagePostCallId: string = "";
	apiResetAcceptCookiesFormCallId: string = "";
	apiResetForgotPasswordPostCallId: string = "";
	apiResetPasswordPostCallId: string = "";
	apiChangePasswordPostCallId: string = "";
	apiResetVerifyEmailPostCallId: string = "";
	apiGetClientProfileApiFormCallId:string="";
	apiCreateMoodPostCallId: string = "";
	apiUpdateMoodPostCallId: string = "";

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
		];
		const storedEmail = localStorage.getItem('rememberedEmail');
		const storedPassword = localStorage.getItem('rememberedPassword');
		this.state = {
			acceptTermsAndPrivacy: false,
			loadingLogin: false,
			visibleForTnC: false,
			visibleForPrivacy: false,
			content_description: '',
			content_type: '',
			full_name: '',
			full_phone_number: '',
			email: storedEmail || '',
			password: storedPassword || '', 
			redirect: false,
			digit1: '',
			digit2: '',
			digit3: '',
			digit4: '',
			digit5: '',
			minutes: 1,
			seconds: 0,
			loadingSignupData: true,
			redirectToOtp: false,
			redirectToDashboard: false,
			loadingOTP: false,
			showCookies: false,
			showCookiesSettings: false,
			settingModalContent: "Controller",
			loginErrorMessage: '',
			tncApiErrorMessage: '',
			otpErrorMessage: '',
			showProfile: false,
			activeKey: [],
			analytical_cookie: false,
			necessary_cookie: false,
			functional_cookie: false,
			loadingProfileData: true,
			office_phone_no: '',
			loadingUpdateProfile: false,
			sub_type: '',
			cookieData: [],
			remember: !!storedEmail && !!storedPassword,
			forgotPasswordErrorMessage: '',
			loadingForgotPassword: false,
			redirectToResetPassword: false,
			loadingEmailData: true,
			loadingResetPassword: false,
			logoutPopup: false,
			resendFlag: false,
			content_descriptions: '',
			showErrorModel: false,
			resendModel: false,
			cookies_status: false,
			toastMessage:'',
			showToast:false,
			loadingChangePassword: false,
			changePasswordErrorMessage: '',
			confirmpassError:"",
			hobbies: [],
			showImage: false,
			popupVisible: false,
			emailChange: false,
			verifyEmailPopup: false,
			profileUpdateData: {},
			loadingSignup: false,
			resetPasswordErrorMessage: "",
			deviceId:"",
			loadingCreateMood: false,
			showAddMood: false,
			selectedMood: '',
			showUpdateMood: false,
			loadingUpdateMood: false,
			isVisible: true,
			notification:false,
			showNotifications:[],
			perPage:10,
			moreData:true,
			collapsed: true,
			isMobile: window.innerWidth < 991,

		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async componentDidMount() {
	
		let timerValue = await getStorageData("timer")
		 timerValue= JSON.parse(timerValue)
		 this.setTimerState(timerValue)
	  }
	  
	  setTimerState=(timerValue: Time)=>{
		
		if(timerValue.minutes == 0 && timerValue.seconds == 0){
			this.setState({minutes:"0",seconds:"59"})
		}
		else if(timerValue)
		{
			
		  this.setState({minutes:timerValue.minutes ,seconds:timerValue.seconds})
		}
	  }

	async receive(from: string, message: Message) {
		this.receiveLogin(message);
		this.receiveReadNotifiacation(message);
		this.receiveReadAllNotifiacation(message);
		this.receivegetNotifiacation(message);
		this.receiveTermsAndConditions(message);
		this.receivePrivacy(message);
		this.receiveShowAccount(message);
		this.receiveSignup(message);
		this.receiveOTPVefirication(message);
		this.receiveDeviceVefirication(message);
		this.resendOTPVefirication(message)
		this.receiveAcceptCookies(message);
		this.receiveGetProfile(message);
		this.receiveUpdateProfile(message);
		this.receiveCookie(message);
		this.receiveAllCookies(message);
		this.receiveForgotPassword(message);
		this.receiveEmail(message);
		this.receiveResetPassword(message);
		this.receiveChangePassword(message);
		this.receiveDeleteProfileImage(message);
		this.receiveVerifyEmail(message);
		this.receiveGetClientProfileDetails(message);
		this.receiveCreateMood(message);
		this.receiveUpdateMood(message);
	}

	async receiveLogin(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetLoginPostCallId &&
			this.apiResetLoginPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (responseJson && !responseJson.errors) {
				localStorage.setItem('token', responseJson.token);
				localStorage.setItem('fullName', responseJson.full_name);
				localStorage.setItem('userType', responseJson.user_type);
				this.setState({ redirectToOtp: true, loadingLogin: false });
				localStorage.removeItem("newDevice");
				window.location.reload()
			} 
			 if (responseJson && responseJson.errors) {
					this.setState({ loadingLogin: false, loginErrorMessage: responseJson.errors[0] && responseJson.errors[0].failed_login })
					if(responseJson.errors[0] && responseJson.errors[0].new_device === "new device detected, enter OTP sent to your registered email to login"){
					window.location.href="/otp"
					localStorage.setItem("newDevice","true")
					localStorage.setItem("token",responseJson.errors[0]?.token)
					localStorage.setItem('phone', responseJson.errors[0]?.full_phone_number);
					this.resendOTP(JSON.stringify(localStorage.getItem("phone")));
					}
			}

		}
	}
	async receiveOTPVefirication(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetOTPPostCallId &&
			this.apiResetOTPPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.meta) {
				localStorage.setItem('token', responseJson.meta.token);
				localStorage.setItem('userType', responseJson.meta.user_type);
				this.setState({ redirectToDashboard: true, loadingOTP: false});
				window.location.reload()
			} else if (responseJson && responseJson.errors) {
				if (responseJson.errors[0]?.pin) {
					this.setState({ loadingOTP: false, otpErrorMessage: responseJson.errors[0]?.pin })
					return;

				}
			}
		}
	}
	async receiveDeviceVefirication(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetDevicePostCallId &&
			this.apiResetDevicePostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && !responseJson.errors){
				localStorage.setItem('token', responseJson.token);
				localStorage.setItem('userType', responseJson.user_type);
				localStorage.removeItem("newDevice");
				this.setState({ redirectToDashboard: true, loadingOTP: false});
				window.location.reload()
			} 
			if (responseJson && responseJson.errors) {
				if (responseJson.errors[0] && responseJson.errors[0].otp) {
					this.setState({ loadingOTP: false, otpErrorMessage: responseJson.errors[0] && responseJson.errors[0].otp })
					this.setState({digit1:"",digit2:"",digit3:"",digit4:"",digit5:""})
					return;

				}
			}
		}
	}
	//resend OTP
	async resendOTPVefirication(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResendOTPPostCallId &&
			this.apiResendOTPPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.token) {
				localStorage.setItem('token', responseJson.token);
				window.location.reload();
				this.setState({ resendModel: true })
			}else {
				if(responseJson.errorMessage){
					FailedToast(responseJson.error);
				}
			}
		}
	}
	receiveTermsAndConditions(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetGetTermsAndConditionFormCallId &&
			this.apiResetGetTermsAndConditionFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({
				visibleForTnC: true,
				content_description: responseJson?.setting?.data?.map((res: any) => res.attributes?.content_description),
				content_type: responseJson?.setting?.data?.map((res: any) => res.attributes?.content_type)
			});


		}
	}

	receivePrivacy(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetGetPrivacyFormCallId &&
			this.apiResetGetPrivacyFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({
				visibleForPrivacy: true,
				content_description: responseJson?.setting?.data?.map((res: any) => res.attributes?.content_description),
				content_type: responseJson?.setting?.data?.map((res: any) => res.attributes?.content_type)
			});


		}
	}
	receiveCookie(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetGetCookieFormCallId &&
			this.apiResetGetCookieFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({ cookieData: responseJson.setting?.data });
		}
	}
	receiveAllCookies(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetGetAllCookieFormCallId &&
			this.apiResetGetAllCookieFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({ content_descriptions: responseJson.content_descriptions });
		}
	}

	receiveShowAccount(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetShowAccountFormCallId &&
			this.apiResetShowAccountFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({
				full_name: responseJson?.data?.attributes?.full_name,
				full_phone_number: responseJson?.data?.attributes?.full_phone_number,
				email: responseJson?.data?.attributes?.email,
				loadingSignupData: false,
				cookies_status: responseJson?.data?.attributes?.cookies_status,
			});


		}
	}

	receiveSignup(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetSignupPostCallId &&
			this.apiResetSignupPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.data) {
				localStorage.setItem('token', responseJson.meta.token);
				this.setState({ redirectToOtp: true, loadingSignup: false });
			}
			else if (responseJson && responseJson.errors) {
				if (responseJson.errors[0]?.password) {
					FailedToast( responseJson.errors[0]?.password);
				}
			}  else if (responseJson && responseJson.message) {
				this.setState({showErrorModel:true, loadingSignup: false});
			}else if (responseJson && responseJson.error) {
				FailedToast( responseJson.error);
			}


		}
	}

	receiveAcceptCookies(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetAcceptCookiesFormCallId &&
			this.apiResetAcceptCookiesFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson) {
				this.setState({
					analytical_cookie: responseJson.analytical_cookie,
					necessary_cookie: responseJson.necessary_cookie,
					functional_cookie: responseJson.functional_cookie,
					showCookies: false, showCookiesSettings: false
				});
			}


		}
	}

	receiveGetProfile(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetGetProfileFormCallId &&
			this.apiResetGetProfileFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.data) {
				this.getClientProfile();
				localStorage.setItem('userID', responseJson?.data?.map((res: any) => res.id));
				this.setState({
					full_phone_number: responseJson.data[0].attributes.full_phone_number,
					full_name: responseJson.data[0].attributes.full_name,
					email: responseJson.data[0].attributes.email,
					office_phone_no: responseJson.data[0].attributes.office_phone_no,
					profileImageUrl: responseJson.data[0].attributes.photo,
					hobbies: responseJson.data[0].attributes.hobbies?.map((e: any) => e.title)
				});
				this.setState({loadingProfileData: false});
				
			}


		}
	}
	receiveUpdateProfile(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetUpdateProfilePostCallId &&
			this.apiResetUpdateProfilePostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.meta) {
				this.getProfile();
				this.setState({ loadingUpdateProfile: false, showProfile: false });
			} else if (responseJson && responseJson.errors) {
				if (responseJson.errors[0]?.phone_number) {
					FailedToastCenter("Phone number has already been taken");
					this.setState({ loadingUpdateProfile: false});
				}else if (responseJson.errors[0]?.full_phone_number) {
					FailedToastCenter( responseJson.errors[0]?.full_phone_number);
					this.setState({ loadingUpdateProfile: false});
				}
			}else if (responseJson && responseJson.error){
				FailedToastCenter(responseJson.error);
				this.setState({ loadingUpdateProfile: false});
			}


		}
	}

	async receiveForgotPassword(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetForgotPasswordPostCallId &&
			this.apiResetForgotPasswordPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	
			 if(responseJson && responseJson.success){
				this.setState({loadingForgotPassword: false, forgotPasswordErrorMessage: ""});
				this.showToastMessage("Link has been sent on the given email with an option to re-send link")
			}else if (responseJson && responseJson.errors) {
				if (responseJson.errors[0]?.message) {
					this.setState({ loadingForgotPassword: false, forgotPasswordErrorMessage: responseJson.errors[0]?.message })
					return;
				}else if(responseJson.errors[0]?.account){
					this.setState({ loadingForgotPassword: false, forgotPasswordErrorMessage: responseJson.errors[0]?.account })
					return;
				}
			}
		}
	}

	async receiveGetClientProfileDetails(message:any){
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetClientProfileApiFormCallId &&
			this.apiGetClientProfileApiFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			
			if(responseJson?.client_details){
				localStorage.setItem("caseID", responseJson.client_details.id);
				localStorage.setItem("clientId", responseJson.client_details.attributes.client_id);
			}
			
		}
	}
	resendopt(values: string): boolean {
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
		};

		const httpBody = {
			"full_phone_number": values,
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResendOTPPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.resendOTPApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	receiveEmail(message: any) {
		if (
		  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
		  this.apiResetGetEmailFormCallId &&
		  this.apiResetGetEmailFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
		
		  let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		  );
		 
		  this.setState({
			email: responseJson?.data?.map((res: any) => res.attributes?.email),
			loadingEmailData: false
		});
		}
	  }
	  async receiveResetPassword(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetPasswordPostCallId &&
			this.apiResetPasswordPostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	
			if (responseJson && responseJson.meta) {
				this.setState({ loadingResetPassword: false, redirect: true });
				SuccessMessage("Password changed successfully");
			} else if (responseJson && responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message) {
				this.setState({ loadingResetPassword: false, resetPasswordErrorMessage: responseJson.errors[0].message });
			}
				
			  }
		}
	
	async receiveVerifyEmail(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetVerifyEmailPostCallId &&
			this.apiResetVerifyEmailPostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	
			 if(responseJson && responseJson.message){
				this.setState({ redirect: true});
				SuccessMessage("Your email has been verified successfully")
			  }
		}
	}
	async receiveDeleteProfileImage(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiDeleteProfileImagePostCallId &&
			this.apiDeleteProfileImagePostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	
			 if(responseJson && responseJson.message){
				this.setState({ profileImageUrl: null });
			  }
		}
	}
	async receiveChangePassword(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiChangePasswordPostCallId &&
			this.apiChangePasswordPostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			 if(responseJson && responseJson.message){
				this.setState({ loadingChangePassword: false, changePasswordErrorMessage: ""});
				SuccessMessage("Password changed successfully");
				window.history.back();
			  }else {
				if(Array.isArray(responseJson.errors)){
				this.setState({loadingChangePassword: false, changePasswordErrorMessage:"",confirmpassError: responseJson.errors[0]?.message })
				}
				else{
					this.setState({loadingChangePassword: false,confirmpassError:"", changePasswordErrorMessage: responseJson.error })
					
				}
			}
		}
	}

	async receiveCreateMood(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiCreateMoodPostCallId &&
			this.apiCreateMoodPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.client_mood){
				this.setState({ showAddMood: false,loadingCreateMood: false });
				SuccessMessage("Mood Added successfully");
				window.location.reload();
			}else{
				FailedToastCenter(responseJson.error);
				this.setState({ showAddMood: false,loadingCreateMood: false });
		}
	}
	}
	async receiveUpdateMood(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiUpdateMoodPostCallId &&
			this.apiUpdateMoodPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.client_mood){
				this.setState({ showUpdateMood: false,loadingUpdateMood: false });
				SuccessMessage("Mood updated successfully");
				window.location.reload();
			}else{
				FailedToastCenter(responseJson.error);
				this.setState({ showUpdateMood: false,loadingUpdateMood: false });
		}
	}
	}
	resetPassword(values: any): boolean {
		this.setState({ loadingResetPassword: true });
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
		  "token": this.getUrlParameter('token')
		};

		const httpBody = {
			"password": values.password,
    		"password_confirmation": values.password_confirmation
			
		};

		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetPasswordPostCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  configJSON.resetPasswordAPiEndPoint
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestBodyMessage),
		  JSON.stringify(httpBody)
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.apiMethodTypeAddDetail
		);
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	  }
	
	forgotPassword(values: any): boolean {
		this.setState({ loadingForgotPassword: true });
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
		};

		const httpBody = {
			...values
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetForgotPasswordPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.forgotPasswordAPiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	changePassword(values: any): boolean {
		this.setState({ loadingChangePassword: true });
		const token = localStorage.getItem('token');
		const header = {
		  "Content-Type": configJSON.contentTypeApiAddDetail,
		  "token": token
		};

		const httpBody = {
			"current_password": values.current_password,
			"new_password": values.new_password,
    		"password_confirmation": values.password_confirmation
			
		};

		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiChangePasswordPostCallId = requestMessage.messageId;

		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  configJSON.changePasswordAPiEndPoint
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestBodyMessage),
		  JSON.stringify(httpBody)
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.updateApiMethodType
		);
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	  }

	getEmail = () => {
		const header = {
		  	"Content-Type": configJSON.contentTypeApiAddDetail,
		 	"token": this.getUrlParameter('token')
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetEmailFormCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		 	configJSON.profileAPiEndPoint
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };  

	  deleteProfileImage(): boolean {
		const token = localStorage.getItem('token');
		const id = localStorage.getItem('userID')
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};

		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiDeleteProfileImagePostCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.updateProfileAPiEndPoint}/${id}`
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.apiDeleteMethodTypeAddDetail
		);
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	  }

	  
	login(values: any): boolean {
		localStorage.setItem('timer', JSON.stringify({
			minutes: 0,
			seconds: 59
		}));

		if (this.state.remember) {
			localStorage.setItem('rememberedEmail', this.state.email);
			localStorage.setItem('rememberedPassword', this.state.password);
		} else {
			localStorage.removeItem('rememberedEmail');
			localStorage.removeItem('rememberedPassword');
		}
		this.setState({ loadingLogin: true });
		localStorage.setItem('email', this.state.email);
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
		};

		const data = {
			type: "email_account",
			attributes: {
				"email": values.email,
				"password": values.password,
				"device_id": this.state.deviceId

			},
		};

		const httpBody = {
			data,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetLoginPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.loginApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		
		return true;
		
	}

	showAccount = (id: any, userToken: any) => {
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetShowAccountFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.showAccountsAPiEndPoint}/${id}?token=${userToken}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	signup(values: any): boolean {
		if (!this.state.acceptTermsAndPrivacy) {
			this.setState({ tncApiErrorMessage: 'Please agree to the Privacy Policy and Terms and Conditions to proceed' })
			return false;
		}
		this.setState({ loadingSignup: true });
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": this.getUrlParameter('token')
		};
		delete values.confirmPassword;

		const data = {
			type: "email_account",
			attributes: { ...values, device_id: this.state.deviceId }
		};
		localStorage.setItem('email', values.email);
		const httpBody = {
			data
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetSignupPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.showAccountsAPiEndPoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	getTermsAndConditions = () => {
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetGetTermsAndConditionFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.settingAPiEndPoint}?content_type=0`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	getPrivacyPolicy = () => {
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetGetPrivacyFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.settingAPiEndPoint}?content_type=1`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	OTPVerify(): boolean {
		this.setState({ loadingOTP: true });
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
		};
		const token = localStorage.getItem('token')
		const httpBody = {
			token: token,
			pin: `${this.state.digit1}${this.state.digit2}${this.state.digit3}${this.state.digit4}${this.state.digit5}`
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetOTPPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.otpApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}


	acceptCookie = (key: number, value: boolean) => {
		let data = null;
		if (key == 1) {
			data = {
				"necessary_cookie": value,
			}
		} else if (key == 2) {
			data = {
				"analytical_cookie": value,
			}
		}
		else if (key == 3) {
			data = {
				"functional_cookie": value
			}
		}

		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": this.getUrlParameter('token')
		};

		const httpBody = data;

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetAcceptCookiesFormCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.cookiesAcceptAPiEndPoint}?token=${this.getUrlParameter('token')}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.updateApiMethodType
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;

	};

	getProfile = () => {
		this.setState({ loadingProfileData: true });
		const token = localStorage.getItem('token');
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetGetProfileFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.profileAPiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};



	updateProfile(values: any): boolean {
		this.setState({ loadingUpdateProfile: true });
		const token = localStorage.getItem('token');
		const id = localStorage.getItem('userID')
		const header = {
			"token": token
		};
		
		let form = new FormData();
		form.append('[profile][full_name]', values.full_name);
		form.append('[profile][full_phone_number]', values.full_phone_number);
		form.append('[profile][email]', values.email);
		form.append('[profile][office_phone_no]', values.office_phone_no || '');
		for(let i in this.state.hobbies){
			form.append(`[profile][hobbies][${i}]`, this.state.hobbies[i])
		}
		if (this.state.profileImage) {
			form.append('[profile][photo]', this.state.profileImage);
		}
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetUpdateProfilePostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.updateProfileAPiEndPoint}/${id}/update_profile`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			form
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.updateApiMethodType
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}


	getCookiePolicy = () => {
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetGetCookieFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.settingAPiEndPoint}?content_type=2`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	getAllCookiePolicy = () => {
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetGetAllCookieFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.getAllCookiesApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	verifyEmail = () => {
		const header = {
		  	"Content-Type": configJSON.contentTypeApiAddDetail,
		 	"token": this.getUrlParameter('token')
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetVerifyEmailPostCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		 	`${configJSON.verifyEmailApiEndPoint}?token=${this.getUrlParameter('token')}&email=${this.getUrlParameter('email')}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };  

	  getClientProfile = () => {
		const token = localStorage.getItem('token');
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetClientProfileApiFormCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.clientProfileAPiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	resendOTP = (values: string) => {
		this.resendopt(values)
	}
	onFinish = (values: any) => {
		this.signup(values);
	};

	onLogin = (values: any) => {
		this.login(values);
		
	};
	showTnCModal = () => {
		this.getTermsAndConditions();
		this.setState({
			visibleForTnC: true,
		});

	}
	showPrivacyModal = () => {
		this.getPrivacyPolicy();
		this.setState({
			visibleForPrivacy: true,
		});

	}
	tncHandleCancel = (event: any) => {
		this.setState({
			visibleForTnC: false,
		});
	}
	privacyHandleCancel = (event: any) => {
		this.setState({
			visibleForPrivacy: false,
		});
	}


	handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
		const { value } = event.target;
		switch (field) {
			case 'digit1':
				this.setState({ digit1: value });
				break;
			case 'digit2':
				this.setState({ digit2: value });
				break;
			case 'digit3':
				this.setState({ digit3: value });
				break;
			case 'digit4':
				this.setState({ digit4: value });
				break;
			case 'digit5':
				this.setState({ digit5: value });
				break;
		}
		if (value && field !== 'digit5') {
			const nextField = `digit${parseInt(field.charAt(5)) + 1}`;
			const nextInput = document.getElementById(nextField) as HTMLInputElement;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (value === '' && field && field.length > value.length) {
			const prevField = `digit${parseInt(field.charAt(5)) - 1}`;
			const prevInput = document.getElementById(prevField) as HTMLInputElement;
			if (prevInput) {
				prevInput.focus();
				
			}
			return;
		}
	};
	

	getUrlParameter(name: any) {
		let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		let results = regex.exec(window.location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}
	hideResendSucessModel = () => {
		this.setState({ resendModel: false })
	}
	hideErrorModel = () => {
		this.setState({ showErrorModel: false })
	}
	showCookiesModal = (event: any) => {
		this.setState({
			showCookiesSettings: false,
			showCookies: true,
		});
	}
	showCookiesSettingsModal = (event: any) => {
		this.getCookiePolicy();
		this.setState({
			showCookies: false,
			showCookiesSettings: true,
		});
	}
	showCookiesHandleCancel = (key: number, value: boolean) => {
		this.acceptCookie(key, value);
	}
	showCookiesSettingHandleCancel = (event: any) => {
		this.setState({
			showCookiesSettings: false,
		});
	}


	validatePassword = (password: string) => {
		const capitalRegex = /[A-Z]/;
		const lowercaseRegex = /[a-z]/;
		const numberRegex = /\d/;
		const spaceRegex = /\s/;
		const lengthRequirement = password.length >= 8;

		const isValid = capitalRegex.test(password) &&
			lowercaseRegex.test(password) &&
			numberRegex.test(password) &&
			!spaceRegex.test(password) &&
			lengthRequirement;

		return isValid;
	}

	passwordValidation = (_: any, value: string) => {
		if (!value) {
			return Promise.reject();
		}
		if (this.validatePassword(value)) {
			return Promise.resolve();
		}
		return Promise.reject();
	}
	

	 validateInput = (_: any, value: string) => {
		if (!value || value.trim() === '') {
			return Promise.resolve(); 
		}
	
		
		const isNumber = !isNaN(parseFloat(value)) ;
	
		if (isNumber) {
		  
		  return Promise.resolve();
		} else {
		
		  return Promise.reject(new Error('Please enter a valid mobile number containing only digits.'));
		}
	  };

	confirmPasswordValidator = (value: string, password: string) => {
		if (!value) {
			return Promise.reject();
		}
		if (!value || password === value) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('Password does not match'));
	}


	onClickCookieSwitch = (event: any) => {
		event.stopPropagation();
	}

	onClick(value: any, key: any) {
		this.acceptCookie(key, value)
	}
	showProfileModal = () => {
		this.getProfile()
		this.setState({
			showProfile: true
		});
	}

	profileHandleCancel = (event: any) => {
		this.setState({
			showProfile: false,
		});
	}
	onUpdateProfile = (values: any) => {
		this.setState({verifyEmailPopup: false})
		this.updateProfile(values);
	};

	getCookieData(key: string): any {
		let _data = this.state.cookieData.filter((e: any) => e.attributes?.sub_type === key);
		if (_data && _data.length) {
			return _data[0]?.attributes?.content_description;
		}

		return null
	}
	handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;
		this.setState({ remember: checked }, () => {
			if (!checked) {
				localStorage.removeItem('rememberedEmail');
				localStorage.removeItem('rememberedPassword');
			} else {
				localStorage.setItem('rememberedEmail', this.state.email);
				localStorage.setItem('rememberedPassword', this.state.password);
			}
		});
	};

	logoutPopupModal = (event: any) => {
		this.setState({
			logoutPopup: true,
		});
	}
	logoutHandleCancel = (event: any) => {
		this.setState({
			logoutPopup: false,
		});
	}
	getColor = () => {
		if (this.state.resendFlag) {
			return "#830065"
		} else {
			return "lightgrey"
		}
	}
	getCursor = () => {
		if (this.state.resendFlag) {
			return "pointer"
		} else {
			return "not-allowed"
		}
	}
	getverifyCursor = () => {
		if (this.state.resendFlag) {
			return "not-allowed"
		} else {
			return "pointer"
		}
	}
	getBackground = () => {
		if (this.state.resendFlag) {
			return "#94A3B8"
		} else {
			return "#830065"
		}
	}
	onClickVerify = () => {
		if (!this.state.resendFlag) {
			this.OTPVerify()
		}
	}
	onClickVerifyDevice = () => {
		if (!this.state.resendFlag) {
			this.VerifyDevice() 
		}
	}
	onForgotPassword = (values: any) => {
		this.forgotPassword(values);
	};
	onResetPassword = (values: any) => {
		this.resetPassword(values);
	  };

	showToastMessage = (message: string) => {
		this.setState({
			toastMessage: message,
			showToast: true
		})

		setTimeout(() => {
			this.setState({
				toastMessage: '',
				showToast: false
			})
		}, 3000)
	}
	onChangePassword = (values: any) => {
		this.changePassword(values);
	  };

	generateDeviceId= async()=>{
		const fp = await FingerprintJS.load();
		const result = await fp.get();
		this.setState({deviceId:result.visitorId})
	}
	
	VerifyDevice(): boolean {
		this.setState({ loadingOTP: true });
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
		};
		const token = localStorage.getItem('token')
		const httpBody = {
			token: token,
			otp_code: `${this.state.digit1}${this.state.digit2}${this.state.digit3}${this.state.digit4}${this.state.digit5}`,
			device_id: this.state.deviceId
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetDevicePostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.resetDevieIdAPiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}


	createMood(mood: string): boolean {
		const token = localStorage.getItem("token");
		this.setState({loadingCreateMood: true});
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};
		const httpBody = {
			"client_mood": mood
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiCreateMoodPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.createMoodApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};
	updateMood(mood: string): boolean {
		const token = localStorage.getItem("token");
		this.setState({loadingUpdateMood: true});
		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			"token": token
		};
		const httpBody = {
			"client_mood": mood
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiUpdateMoodPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.createMoodApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.putApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};
	showNotficationModal= (value:string)=>{
		if(value === "case_controller"){
			return
		}
		else if(value === "solicitor")
		{
			return
		}
		else{
			this.setState({notification:true})
			this.getNotifications()
		}
	}
	closeNotificationMoal= ()=>{
		this.setState({notification:false})
	}
	getNotifications=()=>{
		const pageNo=1

		const token = localStorage.getItem("token");	
		const header = {
		  "Content-Type": configJSON.validationApiContentType,
		  "token": token
		};
	   
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiNotificationCallId = requestMessage.messageId;
			
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		 `${configJSON.getNotificationListing}${pageNo}${configJSON.getNotificationListing2}${this.state.perPage}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.validationApiMethodType
		);
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	  };

	  receivegetNotifiacation = async(message: Message) => {
		  if (
			  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			  this.apiNotificationCallId &&
			  this.apiNotificationCallId ===
			  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		  ) {
			  let responseJson = message.getData(
				  getName(MessageEnum.RestAPIResponceSuccessMessage)
			  );
			  if(responseJson.reminders.data.length === this.state.showNotifications.length){
				this.setState({moreData:false})
			  }
		
			 if(responseJson && responseJson.reminders ){
				  this.setState({ showNotifications: responseJson.reminders.data});
			  }
			
		
  
		  }
	  }
	readNotifications = (id:number) => {

		const token = localStorage.getItem("token");
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			"token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiReadNotificationCallId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getReadNotification}${id}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.updateApiMethodType
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};
	readAllNotifications = () => {
		const token = localStorage.getItem("token");
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			"token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiReadAllNotificationCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.updateAllNotification
		);
	
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.updateApiMethodType
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

	receiveReadNotifiacation = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiReadNotificationCallId &&
			this.apiReadNotificationCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			
			if(responseJson && !responseJson.error ){
				this.getNotifications();
				
			}
		}
	}

	receiveReadAllNotifiacation = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiReadAllNotificationCallId &&
			this.apiReadAllNotificationCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson){
				this.getNotifications();
			}
		
		}
	}
	onNotificationClick = (id:number)=>{
		this.readNotifications(id)
	}
	fetchMoreData = ()=>{
		setTimeout(() => {
			this.setState({perPage:this.state.perPage+10});
			this.getNotifications();
		  },1000);
	
	}
	readAll = ()=>{
		this.readAllNotifications();
	}
  
}
// Customizable Area End
