
// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import { Layout, Menu } from 'antd';
import React from 'react';
const { Sider, Content } = Layout;
import { Link, Redirect } from "react-router-dom";
import './DefaultLayout.css';
import CaseControllerDashboard from '../../blocks/dashboard/src/CaseControllerDashboard.web';
import AssessmentTest from '../../blocks/assessmenttest/src/AssessmentTest.web';
import UserLibrary from '../../blocks/dashboard/src/UserLibrary.web';
import AppointmentDashboard from '../../blocks/appointmentmanagement/src/AppointmentDashboard.web';
import AppointmentDashboardDetails from '../../blocks/appointmentmanagement/src/AppointmentDashboardDetails.web'
import WebRoutesGenerator from './NativeWebRouteWrapper';
import EmailAccountRegistrationController from '../../blocks/email-account-registration/src/EmailAccountRegistrationController.web';
import VibrationIcon from '@material-ui/icons/Vibration';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import ArrowLeft from "./library.png";
import goaltrack from "./goaltrack.svg"

import BrandLogo from "./nav_logo.png";
import ProfileScreen from './ProfileScreen.web';
import CreateCaseScreen from './CreateCaseScreen.web';
import CaseViewScreen from './CaseViewScreen.web';
import AppointmentDetailsScreen from './AppointmentDetailsView';
import SettingsWeb from '../../blocks/settings2/src/SettingsWeb.web';
import TermsAndConditions from '../../blocks/settings2/src/TermsAndConditions.web';
import PrivacyPolicy from '../../blocks/settings2/src/PrivacyPolicy.web';
import SettingWeb from '../../blocks/settings2/src/SettingWeb.web';
import Cookies from '../../blocks/settings2/src/Cookies.web';
import { CalendarOutlined, SettingOutlined, ShakeOutlined, ShoppingOutlined,RiseOutlined } from '@ant-design/icons';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import ChangePassword from './ProfileScreen.web';
import UserLibraryDashboard from '../../blocks/catalogue/src/UserLibraryDashboard.web';
import Physiotherapy from '../../blocks/catalogue/src/Physiotherapy.web';
import ClientSearchScreen from '../../blocks/catalogue/src/ClientSearchScreen.web';
import CreateCaseManagement from '../../blocks/catalogue/src/CreateCaseManagement.web';
import CaseManagementDetails from '../../blocks/catalogue/src/CaseManagementDetails.web';
import UpdateCaseManagement from '../../blocks/catalogue/src/UpdateCaseManagement.web';
import ClientRehabilitation from '../../blocks/catalogue/src/ClientRehabilitation.web';
import CreateEntry from './CreateEntry.web';
import MyRehabJourneyDetails from '../../blocks/catalogue/src/MyRehabJourneyDetails.web';
import GoalManagementDashboard from '../../blocks/goalmanagement/src/GoalManagementDashboard.web';
import GoalManagementDashboardDetail from '../../blocks/goalmanagement/src/GoalManagementDashboardDetail.web';
import TasksListWeb from '../../blocks/tasks/src/TasksList.web';
import TaskDetailsScreen from '../../blocks/tasks/src/TaskDetailsScreen.web';
import moment from 'moment';
import ClientDashboard from '../../blocks/dashboard/src/ClientDashboard.web';
import ViewAllTask from '../../blocks/tasks/src/ViewAllTask.web';
import DashboardDetails from '../../blocks/dashboard/src/DashboardDetails.web';
import AssessmentDetail from '../../blocks/assessmenttest/src/AssessmentDetail/AssessmentDetail.web';
import AssessmentQuestions from '../../blocks/assessmenttest/src/AssessmentQuestions/AssessmentQuestions.web';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
class DefaultLayout extends EmailAccountRegistrationController {
    constructor(props: any) {
        super(props);
    }

    async componentDidMount(){
        this.getProfile()
      }
      getDefaultkey = (defaultSelectedKeys)=>{
        if(defaultSelectedKeys.length ){
            return defaultSelectedKeys
        }
        else if(localStorage.getItem("navLinkKey")){
            console.log("ddd",localStorage.getItem("navLinkKey"));
            
            return localStorage.getItem("navLinkKey")
        }
        else{
            return ["1"]
        }
    }
    handleResize = () => {
        this.setState({ isMobile: window.innerWidth < 991 });
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    toggleCollapsed = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };
      render() {
        const { collapsed, isMobile } = this.state;
        const isLogin = localStorage.getItem('token');
        if (!isLogin) {
            return <Redirect to="/login" />;
        }

        const url = window.location.pathname;
        const defaultSelectedKeys = navs.filter((nav) => url.includes(nav.link)).map((navItem) => navItem.key);
        const userType = localStorage.getItem('userType');
        defaultSelectedKeys.length >0 && localStorage.setItem("navLinkKey",defaultSelectedKeys)
        const roleBasedNavs = {
            case_controller: ["1", "2", "3", "4", "5", "6", "7", "8"],
            guest: ["1", "4", "8"],
            solicitor: ["1", "2", "4", "8"],
            client: ["1", "2", "3", "4", "5", "6", "7", "8"],
            assistant_case_manager: ["1", "2", "3", "4", "5", "6", "7", "8"],
            case_manager: ["1", "2", "3", "4", "5", "6", "7", "8"],
        };
        const filteredNavs = navs.filter(nav => roleBasedNavs[userType].includes(nav.key));

        return  (
            <Layout>
            {/* Sidebar */}
            <Sider
                theme="light"
                width={290}
                collapsedWidth="0"
                collapsed={isMobile ? collapsed : false}
                style={{ 
                    zIndex: 100, 
                    position: isMobile ? 'fixed' : 'relative', 
                    height: '100vh',
                    left: collapsed && isMobile ? '-290px' : '0px',
                    transition: 'left 0.3s ease',
                }}
            >
                <div className='sider'>
                    <img 
                        src={BrandLogo} 
                        style={{ height: '33px', width: '178px' }} 
                        alt="Brand Logo" 
                    />
                </div>
                <Menu 
                    theme="light" 
                    mode="vertical" 
                    defaultSelectedKeys={this.getDefaultkey(defaultSelectedKeys)}
                >
                    {filteredNavs.map(e => (
                        <Menu.Item 
                            key={e.key} 
                            icon={e.icon} 
                            className='menu-item' 
                            style={{ paddingLeft: '29px' }}>
                                <span>{e.title}</span>
                                <Link to={e.link} />
                        </Menu.Item>
                    ))}
                </Menu>
            </Sider>
        
           
            <Layout
                style={{
                    minHeight: '100vh',
                    background: '#FBF1F9',
                    marginLeft: isMobile ? 0 : 0,  
                    transition: 'margin-left 0.3s ease',
                }}
            >
               
                {isMobile && collapsed && (
                    <div
                        className="custom-trigger-button-collapsed"
                    >
                        <MenuUnfoldOutlined style={{color: '#830065', fontSize: '26px',  backgroundColor: 'rgb(248 240 245)',}} onClick={this.toggleCollapsed} />
                    </div>
                )}
                {isMobile && !collapsed && (
                    <div
                        className="custom-trigger-button"
                    >
                        <MenuFoldOutlined style={{color: '#830065', fontSize: '26px',  backgroundColor: 'rgb(248 240 245)',}} onClick={this.toggleCollapsed} />
                    </div>
                )}
        
                <Content className="custom-content">
                    {WebRoutesGenerator({ routeMap })}
                </Content>
            </Layout>
        </Layout>
        );
    }
}

const routeMap = {
    CaseControllerDashboard: {
        component: CaseControllerDashboard,
        path: '/dashboard',
        exact: true
    },
    UserLibraryDashboard: {
        component: UserLibraryDashboard,
        path: '/user-library',
        exact: true
    },
    AppointmentDashboard: {
        component: AppointmentDashboard,
        path: '/appointment',
        exact: true
    },

    AppointmentDashboardDetails: {
        component: AppointmentDashboardDetails,
        path: '/appointment-details',
        exact: true
    },
    ProfileScreen: {
        component: ProfileScreen,
        path: '/profile-screen',
        exact: true
    },
    CreateCaseScreen: {
        component: CreateCaseScreen,
        path: '/create-case-screen',
        exact: true
    },
    CaseViewScreen: {
        component: CaseViewScreen,
        path: '/cases',
        exact: true
    },
    AppointmentDetailsScreen: {
        component: AppointmentDetailsScreen,
        path: '/appointment-details-view',
        exact: true
    },
    SettingsWeb: {
        component: SettingsWeb,
        path: '/about',
        exact: true
    },
    TermsAndConditions: {
        component: TermsAndConditions,
        path: '/terms-and-conditions',
        exact: true
    },
    PrivacyPolicy: {
        component: PrivacyPolicy,
        path: '/privacy-policy',
        exact: true
    },

    SettingWeb: {
        component: SettingWeb,
        path: '/settings',
        exact: true
    },
    Cookies: {
        component: Cookies,
        path: '/cookie-policy',
        exact: true
    },
    ChangePassword: {
        component: ChangePassword,
        path: '/change-password',
        exact: true
    },
    Physiotherapy: {
        component: Physiotherapy,
        path: '/user-library-folder',
        exact: true
    },
    ClientSearchScreen: {
        component: ClientSearchScreen,
        path: '/search-client',
        exact: true
    },
    CreateCaseManagement: {
        component: CreateCaseManagement,
        path: '/create-case-management',
        exact: true
    },
    CaseManagementDetails: {
        component: CaseManagementDetails,
        path: '/case-management-details',
        exact: true
    },
    UpdateCaseManagement: {
        component: UpdateCaseManagement,
        path: '/update-case-management',
        exact: true
    },
    ClientRehabilitation: {
        component: ClientRehabilitation,
        path: '/client-rehabilitation',
        exact: true
    },
    CreateEntry: {
        component: CreateEntry,
        path: '/create-entry',
        exact: true
    },
    MyRehabJourneyDetails: {
        component: MyRehabJourneyDetails,
        path: '/details',
        exact: true
    },
    AssessmentTest: {
        component: AssessmentTest,
        path: '/assessment-test',
        exact: true
    },
    AssessmentTestDetail: {
        component: AssessmentDetail,
        path: '/assessment-details',
        exact: true
    },
    AssessmentQuestions: {
        component: AssessmentQuestions,
        path: '/assessment-question',
        exact: true
    },
    GoalManagementDashboard: {
        component: GoalManagementDashboard,
        path: '/goal-management',
        exact: true
    },
    GoalManagementDashboardDetail: {
        component: GoalManagementDashboardDetail,
        path: '/goal-management-details',
        exact: true
    },
    TasksListWeb: {
        component: TasksListWeb,
        path: '/all-task-list',
        exact: true
    },
    TaskDetailsScreen: {
        component: TaskDetailsScreen,
        path: '/task-details-view',
        exact: true
    },
    ClientDashboard: {
        component: ClientDashboard,
        path: '/client-dashboard',
        exact: true
    },
    ViewAllTask: {
        component: ViewAllTask,
        path: '/view-all-task',
        exact: true
    },
    DashboardDetails: {
        component: DashboardDetails,
        path: '/dashboard-details',
        exact: true
    },
  


}
const navs = [
    {
        key: "1",
        title: "Dashboard",
        link:  (localStorage.getItem('userType') === 'client') ? "/client-dashboard" :  "/dashboard",
        icon: <ShoppingOutlined style={{ fontSize: '20px' }} />
    },
    {
        key: "2",
        title: "Cases",
        link: "/cases",
        icon: <ShoppingOutlined style={{ fontSize: '20px' }} />
    },
    {
        key: "3",
        title: localStorage.getItem('userType') === 'client' ? "My Library": "User Library",
        link: localStorage.getItem('userType') === 'client' ? `/user-library?name=${localStorage.getItem('fullName')}&id=${localStorage.getItem('caseID')}` : '/search-client',
        icon: <VibrationIcon style={{ fontSize: '23px', marginBottom:"-5px"}}/>
    },
    {
        key: "4",
        title: localStorage.getItem('userType') === 'client' ?'My Appointments':'Appointments',
        link:  localStorage.getItem('userType') === 'client' ?'/appointment-details' :'/appointment',
        icon: <CalendarTodayIcon style={{ fontSize: '20px',marginBottom:"-3px" }} />
    },
    {   
        key: "5",
        title: localStorage.getItem('userType') === 'client' ?'My Assessments':'Assessment Tests',
        link:  localStorage.getItem('userType') === 'client' ?'/assessment-details':'/assessment-test',
        icon: <LaptopChromebookIcon style={{ fontSize: '20px',marginBottom:"-3px" }} />
    }
    
   ,{
        key: "6",
        title: localStorage.getItem('userType') === 'client' ? "My Goals": "Goal Management",
        link:  localStorage.getItem('userType') === 'client' ?'goal-management-details':"/goal-management",
        icon:  localStorage.getItem('userType') === 'client' ?<RiseOutlined  style={{ fontSize: '20px' }} /> : <CalendarOutlined style={{ fontSize: '20px' }} />
    },
    {
        key: "7",
        title: localStorage.getItem('userType') === 'client' ? "My Tasks" : "Task Management",
        link: localStorage.getItem('userType') === 'client' ? `/task-details-view?id=${localStorage.getItem('clientId')}&name=${localStorage.getItem('fullName')}&date=${moment(new Date()).format('yyyy-MM-DD')}` : "/all-task-list",
        icon: <SettingOutlined style={{ fontSize: '20px' }} />
    },
    {
        key: "8",
        title: "Settings",
        link: "/settings",
        icon: <SettingOutlined style={{ fontSize: '20px' }} />
    }
  
]
export default DefaultLayout;
// Customizable Area End
