// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './AppointmentStyles.css';
import AppointmentDashboardController from "./AppointmentDashboardController.web";
import PageWrapper from '../../../components/src/Pagewrapper.web';

import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Spin, Table,Row, Col } from 'antd';
import _ from 'lodash';
class AppointmentDashboard extends AppointmentDashboardController {
	constructor(props: any) {
		super(props);
	}
	async componentDidMount() {
		const userType= localStorage.getItem("userType")
		if(userType==="solicitor" || userType==="guest")
		{
			this.getAppointmentsSolicitor()			
		}
		else{
			this.getAppointments();
		}
	}
	columns = [
		{
			title: (<span className="client-name">Client Name</span>),
			width: 280,
			ellipsis: true,
			render: (e: any) => (
				<span className='appointment-name-text'>
					{_.upperFirst(e.attributes.full_name)}
				</span>
			),
		},
		{
			title: (<span className="client-name">Total Appointments</span>),
			width: 280,
			ellipsis: true,
			render: (e: any) => (
				<span className='appointment-name-text'>
					{e.attributes.total_appointments}
				</span>
			),
		},
		{
			title: (<span className="client-name">Action</span>),
			dataIndex: '',
			width: 280,
			key: (e: any) => e.id,
			render: (e: any) => <a className='view-text' onClick={()=>this.goToDetailsScreen(e)}>View</a>,
		},
	];

	CustomEmpty = (loader) => (
		<div style={{ textAlign: 'center',}}>
			<div style={{width:"100%",
			display:"flex",
			justifyContent:"center",
			backgroundColor:"red",
			height:"30%"}}>
		  <Spin spinning={loader} />
			</div>
		  <div
			style={{
			  height: '50px',
			  backgroundSize: 'contain',
			  backgroundRepeat: 'no-repeat',
			  backgroundPosition: 'center',
			  padding:"0px 20px",
			  marginBottom:"10px"
			}}
		  />
		 <span >No Data</span>
		</div>
	  );

	render(): React.ReactNode {
		return (
			<PageWrapper title="Appointments">
				<div className='case-content'>
					<div id='case-id' className='search-content-main' style={{ width: '100%' }}>
						<Row justify="space-between" align="middle" style={{ width: '100%' }}>
							<Col xs={24} sm={12} md={12} lg={12} xl={12}>
								<p className='case-title'>ALL APPOINTMENTS</p>
							</Col>
							<Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right', marginTop: '8px' }}>
								<Space style={{ width: '100%', justifyContent: 'flex-end' }}>
									<Input 
										className='input-search'
										name="search"
										onChange={this.handle_Search}
										placeholder="Search by Client name"
										prefix={<SearchOutlined style={{ color: '#94A3B8', marginLeft: '6px', fontSize: '18px', }} />}
										style={{ width: '100%', maxWidth: '360px', color: '#64748B', fontSize: '16px', borderRadius: '28px', height: '44px', border: '1px solid #CBD5E1', padding: '4px' }}
									
									/>
								</Space>
							</Col>
						</Row>
					</div>
					<Table
						className="custom-table"
						data-testid="table"
						columns={this.columns}
						bordered
						dataSource={this.getDataSource()}
						scroll={{
							y: "calc(100vh - 410px)"
						}}
						locale={{
							emptyText: this.CustomEmpty(this.state.loadingData)
						  }}
					/>
				</div>
			</PageWrapper>
		);
	}
}

export default AppointmentDashboard;
// Customizable Area End