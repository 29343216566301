import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


// Customizable Area Start
import StorageProvider from "../../../../framework/src/StorageProvider";
import { ApiCallInterface, AssessmentItemInterface} from '../IAssessment'
import { SuccessMessage } from "../../../../components/src/Toast";

export interface ModalValue{
  Assessment:string,
  Frequency:string
}
export interface Assessment2{
  id: string,
  type: string,
  attributes: {
      client_id: number,
      account_id: number,
      assessment_name: string,
      assigned_date: string,
      period: string,
      status: string,
      score: string,
      assigned_by: string,
  }
}
export interface HistoryData{
  id: string,
  name:string,
  subDate:string,
  score:string
}
export interface Assessment{
  data: {
      id: number,
      type: string,
      attributes: {
          client_id: number,
          account_id: number,
          assessment_name: string,
          assigned_date: string,
          period: string,
          status: string,
          score: string,
          assigned_by: string,
      }}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  itemContent: AssessmentItemInterface,
  isLoading: boolean;
  isSuccess: boolean;
  isDetailError: boolean,
  clientListLoader:boolean,
  addAssessLoader:boolean,
  assessmentList:Assessment[],
  showAddModal:boolean,
  errorMsg:string,
  showAssessmentPage:boolean,
  userType:string | null
  // Customizable Area End
}

interface SS {
  id: any;

  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  apiGetAssessmentById: string = "";
  apiGetAssessmentListCallId: string = "";
  apiAddAssessmentListCallId :string= ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    // Customizable Area Start


    this.state = {

      // Customizable Area Start
      itemContent: {},
      isDetailError: false,
      isLoading: true,
      isSuccess: false,
      clientListLoader:false,
      addAssessLoader:false,
      assessmentList:[],
      showAddModal:false,
      errorMsg:"",
      userType: localStorage.getItem("userType"),
      showAssessmentPage:true,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  async componentDidMount() {
    this.getAssessmentByIdApi()
    this.apiAssessmentList()
  }

  apiCall = async (data: ApiCallInterface) => {

    const { contentType, method, endPoint, body } = data;

    let token = await StorageProvider.get("USER_TOKEN");
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiAssessmentList = async () => {
    let token = localStorage.getItem("token")
    let clientId = localStorage.getItem("clientId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    this.setState({clientListLoader:true});
    const body = null
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${ configJSON.assessmentData}${clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiGetAssessmentListCallId = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  addAssessment = async (values:ModalValue) => {
    this.setState({addAssessLoader:true})
    let token = localStorage.getItem("token")
    let clientId = localStorage.getItem("clientId")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    this.setState({clientListLoader:true});
    const body = {
      "client_id": clientId,
      "assessment_name": values.Assessment,
      "period": values.Frequency
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.addAssessmentData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiAddAssessmentListCallId = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
 
 
  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.apiGetAssessmentById) {
          this.getAssessmentByIdApiSuccesscallBack(responseJson.data);
        }

      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiGetAssessmentById) {
          this.getAssessmentByIdFailureCallBack();
        }

      }
    }
   this.receiveGetAssessmentList(message);
   this.receiveAddAssessment(message)
    // Customizable Area End
  }

  // Customizable Area Start
  startAssessment() {
    this.props.navigation.navigate('AssessmentQuestions',
      { id: this.getId() })
  }
  receiveAddAssessment = (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiAddAssessmentListCallId &&
			this.apiAddAssessmentListCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({addAssessLoader:false});
			if(responseJson.assessment && !responseJson.errors){
				this.setState({showAddModal:false,addAssessLoader:false})
        SuccessMessage("Assessment Added");
        this.apiAssessmentList();
			}
      else{
        this.setState({errorMsg:responseJson.errors[0]})
      }
		}
  }
  receiveGetAssessmentList= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetAssessmentListCallId &&
			this.apiGetAssessmentListCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({clientListLoader:false});
			if(responseJson.assessments){
				this.setState({assessmentList:responseJson.assessments.data})
			}
      else{
        this.setState({errorMsg:responseJson.message})
      }
			
		}
  }

  getAssessmentByIdApi = async () => {
    this.apiGetAssessmentById = await this.apiCall({
      contentType: configJSON.assessmentByIdApiContentType,
      method: configJSON.getAssessementTestAPiMethod,
      endPoint: `${configJSON.getAssessementTestByIdUrl}/${this.getId()}`,
    });
  };

  getId() {
    return this.props.navigation.getParam('id');
  }


  getAssessmentByIdApiSuccesscallBack = async (data: AssessmentItemInterface) => {

    if (data.attributes?.is_completed === true) {
      this.setState({
        isLoading: false,
        isDetailError: false,
      }, () => {
        this.props.navigation.navigate('AssessmentResult', { id: data.id })
      })
    } else {
      this.setState({
        isLoading: false,
        isDetailError: false,
        isSuccess: true,
        itemContent: data
      })

    }
  };

  getAssessmentByIdFailureCallBack = () => {
    this.setState({
      isLoading: false,
      isDetailError: true,
      isSuccess: false
    })
  };

  onReFetch() {
    this.setState({
      isLoading: true,
      isDetailError: false,
    }, () => {
      this.getAssessmentByIdApi()
    }
    )
  }
  navigate = async()=>{
    const goToDetails = new Message(getName(MessageEnum.NavigationMessage));
      goToDetails.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentQuestions");
  
      goToDetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      goToDetails.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  
      this.send(goToDetails);
  
    }
  getDataSource = () => {
    return this.state.assessmentList
  }
  getPopupContainer = (trigger:any)=>{
    return trigger.parentElement
  }
  showAssessmentModal=()=>{
    this.setState({showAddModal:true});
  }
  cancelButton = (formRef:any)=>{
    this.setState({showAddModal:false});
		formRef?.current?.resetFields();
  }
  saveAddAssessmentForm=(formRef:any)=>{
    this.addAssessment(formRef.current?.getFieldsValue());
		formRef?.current?.resetFields();
  }
  handlepage= ()=>{
    this.setState({showAssessmentPage:false})
  }
  assessmentDetail= (event:Assessment2)=>{
         localStorage.setItem("AssessmentId",event.id)  
         localStorage.setItem("AssessmentStatus",event.attributes.status)  
         localStorage.setItem("AssessmentName",event.attributes.assessment_name); 
         this.navigate();
  }
  convertMinutesToHoursAndMinutes(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let result = hours + " hour";
    if (hours !== 1) {
      result += "s ";

    }
    result += remainingMinutes + " minute";
    if (remainingMinutes !== 1) {
      result += "s";
    }
    return result;
  }
  getButtonName =(status:string)=>{
    if(this.state.userType=== "client"){
      if(status==="reassess"){
        return "Redo"
      }
      else if (status === "pending"){
        return "Take Test"
      }
      else{
        return "View"
      }
    }
    else{
      if(status==="approval_pending"){
        return "Review"
      }
      else{
        return "View"
      }
    }
  }

  // Customizable Area End
}
