import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { createRequest } from "./helpers/create-request";
import { handleResponse } from "./helpers/handle-response";
import { SuccessMessage } from "../../../components/src/Toast";

// Customizable Area Start
interface GetAllClients {
  id: number,
  full_name: string,
  goals: number,
  client_name: string,
  client_id: number
}
interface Showedit{
  title: string
  type: string
}

interface DurationTerm {
  account_groups_group_id: number;
  account_id: number;
  client_id: number;
  client_status: string;
  created_at: Date;
  created_by: string;
  description: string;
  goal_end_date: Date;
  goal_start_date: Date;
  goal_status: string;
  goal_type: string;
  id: number;
  is_active: true
  name: string;
  rewards: null
  target: null
  updated_at: Date;
  updated_by: string;
};
interface EditDurationTerm {
  data:{
    attributes:{
      account_groups_group_id: number;
      account_id: number;
      client_id: number;
      client_status: string;
      created_at: Date;
      created_by: string;
      description: string;
      goal_end_date: Date;
      goal_start_date: Date;
      goal_status: string;
      goal_type: string;
      id: number;
      is_active: true
      name: string;
      rewards: null
      target: null
      updated_at: Date;
      updated_by: string;
}
},
};

interface GetGoal {
  long_term: DurationTerm[],
  short_term: DurationTerm[],
  medium_term: DurationTerm[],
  message: string
}
interface AddLongTermGOalValue {
  title: string;
}
interface AddUpdateValues {
	title: string;
	type:string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  editGoalLoader:boolean
  isClientLoading:boolean;
  token: string;
  email: string;
  password: string;
  passwordVisible: boolean;
  getClientsData: GetAllClients[]
  filteredClientData: GetAllClients[]
  pageTitle: string;
  client_name: string | null;
  client_id: number;
  showAddModel: boolean;
  showShortTermModel: boolean;
  showMediumTermModel: boolean;
  errorMsg: string;
  loadingData: boolean;
  loadingGoalData: boolean;
  goalType: string;
  getGoalData: GetGoal;
  longTermGoalId: number;
  caseID: number,
  userGoalID: number
  showDeleteModel:boolean;
  showEditModel:boolean;
  showEditLongModel:boolean;
  showClientEditModal:boolean;
  goalID:number;
  goalName:string
  clientstatus:string;
  longTermGoalView:boolean
  longGoalId:number | null;
  shortMediumGoal:DurationTerm | null
  isCompleted:boolean
  goalStatus:string,
  loginType:string;
  changeFontColor:boolean;
  selectedValue:string;
  editlongGoal:string | null,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoalManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginGoalWebApi: string = "";
  getAllClientsApiCalledID: string = "";
  getClientGoalApiCallID: string = "";
  getUserGoalApiCallID: string = "";
  apiLongTermGoalPostCallId: string = "";
  apiDeleteGoalFormCallId: string = ""
  apiUpdateGoalCallId: string = ""
  apiEditUserGoalCallId: string = ""
  apiLongTermGoalCallId: string = ""
  apiGoalFlaglCallId:string = ""
  apiPatchGoalCallId: string = "";
  apiGetShortMediumGoalCallId: string = "";
  apiUpdateLongTermGoalCallId: string = "";
  apiShortMTermGoalPostCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      editGoalLoader:false,
      isCompleted:false,
      loadingGoalData:false,
      selectedValue:"",
      isClientLoading: false,
      editlongGoal:null,
      token: "",
      email: configJSON.loginEmail,
      password: configJSON.loginPassword,
      passwordVisible: false,
      getClientsData: [],
      filteredClientData: [],
      pageTitle: "",
      client_name: localStorage.getItem("clientName"),
      client_id: 0,
      changeFontColor:true,
      showAddModel: false,
      errorMsg: "",
      loadingData: false,
      showShortTermModel: false,
      showMediumTermModel: false,
      goalType: "",
      getGoalData: { long_term: [], message: '', short_term: [], medium_term: [] },
      caseID: 0,
      longTermGoalId:0,
      userGoalID: Number(localStorage.getItem("userGoalId")),
      showDeleteModel:false,
      showEditModel:false,
      showEditLongModel:false,
      showClientEditModal:false,
      goalID:0,
      goalName:"",
      clientstatus:"",
      longTermGoalView: Boolean(localStorage.getItem("changeView")),
      longGoalId:null,
      shortMediumGoal:null,
      goalStatus:"pending",
      loginType:""

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGoToGoalCreateWeb = this.handleGoToGoalCreateWeb.bind(this);
    this.handleGoToGoalRetrieveWeb = this.handleGoToGoalRetrieveWeb.bind(this);
    this.handleLoginGoalWeb = this.handleLoginGoalWeb.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleTogglePassword = this.handleTogglePassword.bind(this);
    this.getLongtermGoal = this.getLongtermGoal.bind(this)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.getLongtermGoal(message);
    this.receiveShortMediumTerm(message);
    this.receiveDeleteAppointment(message);
    this.receiveUpdateGoal(message);
    this.receiveUpdateLongTermGoal(message);
    this.receiveReassignGoal(message);
    this.receiveLongtermUpdateGoal(message);
    this.receiveEditUserGoal(message);
    this.getSMTermGoal(message);
    this.receiveGoalFlag(message)


    
    const apiResponseWeb = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    const responseWebJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    const errorWebJson = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );

    if (apiResponseWeb === this.loginGoalWebApi) {
      handleResponse({
        responseJson: responseWebJson,
        errorJson: errorWebJson,
        onSuccess: () => {
          this.handleWebLoginSuccess(responseWebJson);
        },
        onFail: () => {
          this.showAlert(`Get Token Failed`, "Please retry!");
        },
      });
    }
    else if (apiResponseWeb === this.getAllClientsApiCalledID) {

      if (responseWebJson) {
        this.setState({
          getClientsData: responseWebJson.clients,
          isLoading: false,
          filteredClientData: responseWebJson.clients,
          showAddModel: false,
          errorMsg: ""
        })
      }
    }

    else if (apiResponseWeb === this.getClientGoalApiCallID) {
      
      if (responseWebJson) {
        this.setState({
          getGoalData: responseWebJson.goals,
          isLoading: false,
          showAddModel: false,
          errorMsg: ""
        })
      }
    }
    else if (apiResponseWeb === this.getUserGoalApiCallID) {
      
      if (responseWebJson && responseWebJson.error) {
       
        this.setState({errorMsg:responseWebJson.error})
      }
     else{
      this.setState({
        getGoalData: responseWebJson.goals,
        longTermGoalId:responseWebJson.goals && responseWebJson.goals.long_term && responseWebJson.goals.long_term[0].id,
        errorMsg: ""
      })
     }
    }


    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

      const data = message.getData(
        "rowData"
      );
      localStorage.setItem("clientName", data?.full_name);
      localStorage.setItem("caseID", data?.id);
      this.setState({ client_name: data?.full_name, client_id: data?.id })

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const authToken = await getStorageData("token", false);
    this.handleSetTokenWeb(authToken);
    this.getAllClients(authToken)
    
  }
  handleSetTokenWeb(authToken: string) {
    if (authToken) {
      this.setState({
        token: authToken,
      });
    }
  }
  handleLoginGoalWeb() {
    this.setState({
      isLoading: true,
    });

    const { email, password } = this.state;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.loginGoalWebApi = requestMessage.messageId;

    const body = {
      data: {
        attributes: {
          email: email,
          password: password,
        },
        type: "email_account",
      },
    };

    createRequest({
      requestMsg: requestMessage,
      endPoint: configJSON.loginURLEndPoint,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  }
  handleWebLoginSuccess(input: {
    meta: {
      id: number;
      token: string;
      refresh_token: string;
    };
  }) {
    const { token: respToken, id } = input.meta;
    const { email } = this.state;
    this.setState({
      token: respToken,
      isLoading: false,
    });
    setStorageData("token", respToken);
    setStorageData("loginEmail", email);
    setStorageData("accountId", id.toString());
  }
  handleGoToGoalCreateWeb() {
    this.props.navigation.navigate("GoalCreate");
  }
  handleGoToGoalRetrieveWeb() {
    this.props.navigation.navigate("GoalRetrieve");
  }

  handleChangeEmail(event: { target: { value: string } }) {
    this.setState({
      email: event.target.value,
    });
  }
  handleChangePassword(event: { target: { value: string } }) {
    this.setState({
      password: event.target.value,
    });
  }
  handleSignOut() {
    this.setState({
      token: "",
    });
    removeStorageData("token");
    removeStorageData("loginEmail");
    removeStorageData("accountId");
  }
  handleTogglePassword() {
    let { passwordVisible } = this.state;
    this.setState({ passwordVisible: !passwordVisible });
  }

  getAllClients = (token: string) => {

    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllClientsApiCalledID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClientApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDataSource = () => {
    return this.state.filteredClientData
  }

  handle_Search = (event: string) => {
    const search_Text = event.toLowerCase();
    const filtered_Data = this.state.getClientsData.filter((item: GetAllClients) =>
      item.full_name.toLowerCase().includes(search_Text)
    );

    this.setState({ filteredClientData: filtered_Data });
  };

  goToDetailsScreen = (rowData: DurationTerm) => {
    const goToDetails = new Message(getName(MessageEnum.NavigationMessage));
    const path = `/goal-management-details/${rowData.id}`;
    
    goToDetails.addData(getName(MessageEnum.NavigationMessage), path);
    goToDetails.addData(getName(MessageEnum.NavigationTargetMessage), `GoalManagementDashboardDetail`);
    goToDetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData("rowData", rowData);

    goToDetails.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(goToDetails);
  }

  showLongtermModel = () => {
    this.setState({ errorMsg: "", showAddModel: true, goalType: "long_term" });
  }
  showShorttermModel = () => {
    this.setState({ errorMsg: "", showAddModel: true, goalType: "short_term" });
  }
  showMediumtermModel = () => {
    this.setState({ errorMsg: "", showAddModel: true, goalType: "medium_term" });
  }
  hideModel = () => {
    this.setState({ errorMsg: "", showAddModel: false, showMediumTermModel: false,
     showShortTermModel: false,showEditModel:false, showDeleteModel:false,showClientEditModal:false ,showEditLongModel:false});
  }

  saveLongTermData = (values: AddLongTermGOalValue,) => {
    if(this.state.goalType === "long_term"){
      this.addLongTermGoal(values);
    }
    else{
      this.addShortMediumTermGoalData(values);

    }

  };

  addLongTermGoal(values: AddLongTermGOalValue): boolean {
    const caseID = localStorage.getItem("caseID");
    const token = localStorage.getItem("token");
    this.setState({ loadingData: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const httpBody = {
      data: {
        "name":values.title,
        "goal_type": this.state.goalType,
        "client_id":caseID,
      }
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLongTermGoalPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addLongGoalApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addShortMediumTermGoalData(values: AddLongTermGOalValue): boolean {
    const caseID = localStorage.getItem("caseID");
    const token = localStorage.getItem("token");
    this.setState({ loadingGoalData: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const bodyData = {
      data: {
        "name": values.title,
        "goal_type": this.state.goalType,
        "client_id": caseID,
        "long_term_goal_id": this.state.longTermGoalId ? this.state.longTermGoalId : localStorage.getItem("longTermGoalId")
      }
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiShortMTermGoalPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addLongGoalApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async getLongtermGoal(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiLongTermGoalPostCallId &&
      this.apiLongTermGoalPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.goal) {

        this.setState({ loadingData: false, showAddModel: false, errorMsg: "",goalStatus:"pending" });
        const goaltype= this.state.goalType.replace(/_/g, " ")
        this.state.goalType !== "long_term" && this.getShortMediumGoal();
        SuccessMessage(`${goaltype.charAt(0).toUpperCase()}${goaltype.slice(1)} Goal Added`);
        const authToken = await getStorageData("token", false);
        this.getClientGoals(authToken, this.state.userGoalID);
      } else if (responseJson.error) {
        this.setState({ loadingData: false, errorMsg: responseJson.error });
      } else {
        this.setState({ loadingData: false, errorMsg: responseJson.error });
      }


    }
  }
  async getSMTermGoal(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiShortMTermGoalPostCallId &&
      this.apiShortMTermGoalPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.goal) {

        this.setState({ loadingData: false, showAddModel: false, errorMsg: "",loadingGoalData:false });
        const goaltype= this.state.goalType.replace(/_/g, " ")
        this.state.goalType !== "long_term" && this.getShortMediumGoal();
        SuccessMessage(`${goaltype.charAt(0).toUpperCase()}${goaltype.slice(1)} Goal Added`);
        const authToken = await getStorageData("token", false);
        this.getClientGoals(authToken, this.state.userGoalID);
      }  else {
        this.setState({ loadingData: false, errorMsg: responseJson.error,loadingGoalData:false });
      }


    }
  }

  getClientGoals = (token: string | null, caseID: number) => {
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientGoalApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClientGoalApiEndPoint}${caseID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

   getData = (event: any) => {
    localStorage.setItem("userGoalId",event.id)
    localStorage.setItem("changeView",JSON.stringify(true));
    this.setState({longTermGoalView:true})
    this.goToDetailsScreen(event)
  }
  
  changeDateFormate = (dateString :Date)=>{
   const date = new Date(dateString);
   const day = date.getDate();
   const month = date.toLocaleString('default', { month: 'short' });
   const year = date.getFullYear();
   const formattedDate = `${day} ${month} ${year}`;
return formattedDate
  }

  handleCondition = (condition:boolean, truePart:string, falsePart:string) => {
    return condition ? truePart : falsePart
  }

  deleteGoal = (id: number) => {
    this.setState({ isLoading: true });
		const token = localStorage.getItem("token");
		const header = {
			"token": token
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiDeleteGoalFormCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getDeleteGoalApiEndPoint}${id}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.deleteApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   receiveDeleteAppointment = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiDeleteGoalFormCallId &&
			this.apiDeleteGoalFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({ isLoading: false, showDeleteModel: false })
      this.getShortMediumGoal()
			SuccessMessage(responseJson.message);
      const authToken = await getStorageData("token", false);
      this.getClientGoals(authToken, this.state.userGoalID);

		}
	}
  editGoal = (values: Showedit) => {
    this.updateGoal(values)
  }
  editLongtermGoal = () => {
    this.updateLongGoalTitle()
  }
  editLongGoalTitle=(event:any)=>{
    this.setState({editlongGoal:event.target.value})
  }
  editClientGoal = (values: Showedit) => {
    this.editUserGoal(values)
  }

  showDeleteModel = (event: EditDurationTerm) => {
		this.setState({ showDeleteModel: true,goalName:event.data && event.data.attributes.name, goalID: event.data && event.data.attributes.id })
	}

  showLongTermDeleteModel = (event: DurationTerm) => {
		this.setState({ showDeleteModel: true,goalName:event.name, goalID: event.id })
	}

  showEditModel = (event: EditDurationTerm) => {
		this.setState({goalType:"short_term", showEditModel: true, goalID: event.data.attributes.id , goalName: event.data.attributes.name, clientstatus: event.data.attributes.client_status,selectedValue:event.data.attributes.client_status})
  }
  showEditLongModel = (event: DurationTerm) => {
		this.setState({ goalType:"long_term",editlongGoal:event.name,showEditLongModel: true, goalID: event.id , goalName: event.name, clientstatus: event.client_status})
  }


  showClientEditModel = (event: EditDurationTerm) => {
		this.setState({ showClientEditModal: true, goalID: event?.data?.attributes?.id , clientstatus: event?.data?.attributes?.client_status})
	}

	hideDeleteModel = () => {
		this.setState({ showDeleteModel: false, goalID: 0 })
	}
  
  reassignGoal = (event: EditDurationTerm) => {
    const token = localStorage.getItem("token");
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			"token": token
		};

		const httpBody = {
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiPatchGoalCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getUpdateGoalApiEndPoint}${event.data && event.data.attributes.id}${configJSON.reassign}${this.state.userGoalID}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.patchApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

  updateGoal = (values: AddUpdateValues) => {
    
		const token = localStorage.getItem("token");
		this.setState({ editGoalLoader: true });
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			"token": token
		};

		const httpBody = {
      data:{
        "name": values.title,
        "client_id": this.state.userGoalID,
        "goal_status": "pending",
        "client_status": this.state.selectedValue
		}
  };
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiUpdateGoalCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getClientGoalUpdateApiEndPoint}${this.state.goalID}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.putApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};
  updateLongGoalTitle = () => {
    
		const token = localStorage.getItem("token");
		this.setState({ isLoading: true });
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			"token": token
		};

		const httpBody = {
      data:{
        "name": this.state.editlongGoal
		}
  };
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiUpdateLongTermGoalCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getLongTermGoal1}${this.state.goalID}${configJSON.getLongTermGoal3}`
		);
    
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.patchApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

  receiveUpdateLongTermGoal = async(message: Message) => {
      const token =  localStorage.getItem("token");
			const clientId = localStorage.getItem("caseID");
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiUpdateLongTermGoalCallId &&
			this.apiUpdateLongTermGoalCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
      
			if(responseJson && !responseJson.error ){
				this.setState({ isLoading: false, showEditLongModel: false ,errorMsg:"" });
        this.getClientGoals(token, Number(clientId))
        SuccessMessage("Goal Updated Successfully")
			}
			else{
				this.setState({  isLoading: false,loadingData: false,errorMsg:responseJson.error});
			}

		}
	}

   receiveUpdateGoal = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiUpdateGoalCallId &&
			this.apiUpdateGoalCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.goal){
				this.setState({ editGoalLoader: false, showEditModel: false ,errorMsg:"" });
        this.getShortMediumGoal();
				SuccessMessage("Goal Updated successfully");
        const authToken = await getStorageData("token", false);
        this.getClientGoals(authToken, this.state.userGoalID);
			}else if(responseJson.error){
				this.setState({ loadingData: false,editGoalLoader: false,errorMsg:responseJson.error});
			}else{
				this.setState({ loadingData: false,errorMsg:responseJson.errors[0]});
			}

		}
	}

  editUserGoal = (values: AddUpdateValues) => {
    
		const token = localStorage.getItem("token");
		this.setState({ isClientLoading: true });
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			"token": token
		};

		const httpBody = {
  };
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiEditUserGoalCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getUserEditGoalEndApi}${this.state.goalID}${configJSON.getClientStatus}${values.type}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.putApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};
  
  receiveEditUserGoal = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiEditUserGoalCallId &&
			this.apiEditUserGoalCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.goal){
				this.setState({ isClientLoading: false, showClientEditModal: false ,errorMsg:"" });
				SuccessMessage("Goal Updated successfully");
        const authToken = await getStorageData("token", false);
        this.getUserGoals(authToken, this.state.client_id);
        this.getShortMediumGoal();
			}else if(responseJson.error){
				this.setState({ loadingData: false,isLoading: false,errorMsg:responseJson.error});
			}else{
				this.setState({ loadingData: false,errorMsg:responseJson.errors[0]});
			}

		}
	}

   receiveReassignGoal = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiPatchGoalCallId &&
			this.apiPatchGoalCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.goal){
        this.getShortMediumGoal();
				SuccessMessage("Reassigned Goal successfully");
        const authToken = await getStorageData("token", false);
        this.getClientGoals(authToken, this.state.userGoalID);
        this.setState({ loadingData: false,isLoading: false,goalStatus:"pending"});
        localStorage.setItem("longGoalStatus","pending")
			}else if(responseJson.error){
				this.setState({ loadingData: false,errorMsg:responseJson.error,goalStatus:"pending"});
			}else{
				this.setState({ loadingData: false,errorMsg:responseJson.errors[0],goalStatus:"pending"});
			}
		}
	}
  getPopupContainer = (trigger:any)=>{
    return trigger.parentElement
  }
  saveFormDetail =(formRef:any)=>{
    this.setState({showEditModel:false,changeFontColor:true})
    this.editGoal(formRef.current?.getFieldsValue());
		formRef?.current?.resetFields();
  }
  saveLongFormDetail =(formRef:any)=>{
    this.setState({showEditLongModel:false,changeFontColor:true})
    this.editLongtermGoal();
		formRef?.current?.resetFields();
  }
  saveClientEditForm =(formRef:any)=>{
    this.editClientGoal(formRef.current?.getFieldsValue());
		formRef?.current?.resetFields();
  }
  cancelButton= (formRef:any)=>{
    this.hideModel()
    formRef?.current?.resetFields();
  }
  editCancelButton= (formRef:any)=>{
    this.hideModel()
   formRef.current?.resetFields();
  }
  longTermCancelBtn = (formRef: any)=>{
    this.hideModel()
		formRef.current.resetFields();
  }
  goBack= ()=>{
      window.history.back()
  }
  saveLongtermData= (formRef:any)=>{
     this.saveLongTermData(formRef.current?.getFieldsValue());
		 formRef.current.resetFields();
     localStorage.setItem("longGoalStatus","pending")
  }

  changeGoalstatus(data:string){
    const token = localStorage.getItem("token");
    const goalid=localStorage.getItem("longTermGoalId")
		this.setState({ isLoading: true });
		const header = {
			"Content-Type": configJSON.validationApiContentType,
		};

		const httpBody = {
      data: {
        goal_status: data
    }
  };
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiLongTermGoalCallId = requestMessage.messageId;
    
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getLongTermGoalUpdateApi}${goalid}${configJSON.getLongTermGoalUpdateApi2}${token}`
		);
    
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.patchApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
  }
  changeGoalFlag(){
    const token = localStorage.getItem("token");
    const goalid=localStorage.getItem("longTermGoalId")
		this.setState({ isLoading: true });
		const header = {
			"Content-Type": configJSON.validationApiContentType,
      "token": token
		};

		const httpBody = {
  };
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiGoalFlaglCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.changeFlag}${goalid}${configJSON.changeFlag2}`
		);
    
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.patchApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
  }
  receiveGoalFlag = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGoalFlaglCallId &&
			this.apiGoalFlaglCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if(responseJson && responseJson.goal){
        this.setState({ loadingData: false,isLoading: false,isCompleted:responseJson.goal.data.attributes && responseJson.goal.data.attributes.is_completed});
			}
			else{
				this.setState({ loadingData: false,errorMsg:responseJson && responseJson.errors,isCompleted:false});
			}
		}
	}
  receiveLongtermUpdateGoal = async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiLongTermGoalCallId &&
			this.apiLongTermGoalCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.goal){
				SuccessMessage("Goal Upadated Successfully");
        const authToken = await getStorageData("token", false);
        localStorage.setItem("longGoalStatus",this.state.goalStatus)
        this.getClientGoals(authToken, this.state.userGoalID);
        this.setState({ loadingData: false,isLoading: false});
			}else if(responseJson.error){
				this.setState({ loadingData: false,errorMsg:responseJson.error,goalStatus:"pending"});
			}else{
				this.setState({ loadingData: false,errorMsg:responseJson.errors,goalStatus:"pending"});
			}
		}
	}

  getUserGoals = (token: string | null, caseID: number) => {
    
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserGoalApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserGoalsEndApi}${caseID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  viewClientGoals = (event:DurationTerm)=>{
    this.setState({longTermGoalView:false,longGoalId:event.id,goalStatus:event.goal_status})
    localStorage.setItem("longGoalName",event.name);
    localStorage.setItem("longTermGoalId",JSON.stringify(event.id));
    localStorage.setItem("longGoalStatus",event.goal_status);
    localStorage.setItem("changeView","");
    this.getShortMediumGoal();
  }

  getShortMediumGoal = () => {
    
		const token = localStorage.getItem("token");
    const id=localStorage.getItem("longTermGoalId");
		this.setState({ isLoading: true });
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			"token": token
		};


		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiGetShortMediumGoalCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getShortMediumGoal}${id}${configJSON.getShortMediumGoal2}`
		);
    
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getApiMethodType
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

  receiveShortMediumTerm= async(message: Message) => {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetShortMediumGoalCallId &&
			this.apiGetShortMediumGoalCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if(responseJson && !responseJson.error){
        this.setState({ loadingData: false,isLoading: false, shortMediumGoal:responseJson});
			}
      else{
				this.setState({ loadingData: false,errorMsg:responseJson.error});
			}
		}
	}

  changeLongTermGoal = (event:DurationTerm)=>{
    localStorage.setItem("longTermGoalId",JSON.stringify(event))
    this.changeGoalFlag()
  }

  LongTermDropDown = (value: any)=>{
  this.changeGoalstatus(value.target && value.target.value)
  this.setState({goalStatus:value.target && value.target.value})
  }
  changeState= ()=>{
		this.setState({longTermGoalView:true})
	}
  changeColor= ()=>{
    this.setState({changeFontColor:!this.state.changeFontColor})
  }
  selectedvalue= (value:string)=>{
  this.setState({selectedValue:value})    
  }
  // Customizable Area End
}
